<div>
  <app-loading-spinner *ngIf="!termDetails && !apiError"></app-loading-spinner>
  <app-api-error-display *ngIf="apiError" [error]="apiError"></app-api-error-display>

  <div id="term-details" class="details-page" *ngIf="termDetails">
    <app-social-contact></app-social-contact>

    <div id="term-details-menu">
      <app-details-page-menu *ngIf="termDetails"
                             [title]="displayTermId"
                             [menuItems]="menuItems"></app-details-page-menu>
    </div>

    <app-term-page-summary [termDetails]="termDetails"></app-term-page-summary>

    <div class="no-annotations" *ngIf="!hasAnnotations()">
      No annotations for {{displayTermId}}
    </div>
    <div class="has-annotations" *ngIf="hasAnnotations()">

    <div *ngIf="annotationFeatureType == 'gene' && annotatedGeneCount > 0" class="term-page-view-change">
      <a routerLink="/term_genes/{{termDetails.termid}}"
         routerLinkActive="active">View {{annotatedGeneCount}} gene{{annotatedGeneCount>1?'s':''}} annotated with this term ...</a>
    </div>
    </div>

    <app-term-page-widgets [termDetails]="termDetails"></app-term-page-widgets>

    <div *ngIf="annotationFeatureType == 'genotype' && singleLocusGenotypeCount > 0" class="term-page-view-change">
      <div>
        <a routerLink="/term_single_locus_genotype_genes/{{termDetails.termid}}"
           routerLinkActive="active">View {{singleLocusGenotypeGeneCount}} gene{{singleLocusGenotypeGeneCount>0?'s':''}} from single locus genotypes associated with this term ...</a>
      </div>
      <div>
        <a routerLink="/term_genotypes/{{termDetails.termid}}">View {{singleLocusGenotypeCount}} single locus genotype{{singleLocusGenotypeCount>0?'s':''}} associated with this term ...</a>
      </div>
    </div>

    <div>
    <div *ngFor="let annotationTypeName of annotationTypeNames">

      <div *ngIf="termDetails.cv_annotations[annotationTypeName]">
        <app-annotation-table id="{{annotationTypeName}}"
                              *ngIf="annotationTypeName != 'quantitative_gene_expression'"
                              [scope]="'term'"
                              [tableDisplayName]="makeTableDisplayName(annotationTypeName)"
                              [annotationTypeName]="annotationTypeName"
                              [featureInFirstColumn]="true"
                              [hideColumns]="['genotype_background', 'allele_promoters']"
                              [annotationTable]="termDetails.cv_annotations[annotationTypeName]"></app-annotation-table>


      </div>

      <div *ngIf="annotationTypeName == 'double_mutant_genetic_interactions'">
        <app-genetic-interaction-annotation-table
          *ngIf="termDetails.double_mutant_genetic_interactions.length > 0"
          id="{{annotationTypeName}}" [annotationTypeName]="annotationTypeName"
          [tableHeader]="'Genetic interactions where ' + termDetails.termid + ' is the double allele phenotype'"
          [annotationTable]="termDetails.double_mutant_genetic_interactions">
        </app-genetic-interaction-annotation-table>
      </div>

      <div *ngIf="annotationTypeName == 'single_allele_genetic_interactions'">
        <app-genetic-interaction-annotation-table
          *ngIf="termDetails.single_allele_genetic_interactions.length > 0"
          id="{{annotationTypeName}}" [annotationTypeName]="annotationTypeName"
          [tableHeader]="'Genetic interactions where ' + termDetails.termid + ' is the single mutant phenotype'"
          [annotationTable]="termDetails.single_allele_genetic_interactions">
        </app-genetic-interaction-annotation-table>
      </div>
    </div>
  </div>
</div>
