<div *ngIf="panelId == 0">
<p>Broad functional profiling of fission yeast proteins using phenomics and machine learning.</p>



<p>Originally published in <a href='https://doi.org/10.7554/eLife.88229'>Elife</a>.</p>

</div>
<div *ngIf="panelId == 1">
<p>Microtubule competition and cell growth recenter the nucleus after anaphase in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1091/mbc.E23-01-0034'>Mol Biol Cell</a>.</p>

</div>
<div *ngIf="panelId == 2">
<p>Actin-Microtubule Crosstalk Imparts Stiffness to the Contractile Ring in Fission Yeast.</p>



<p>Originally published in <a href='https://doi.org/10.3390/cells12060917'>Cells</a>.</p>

</div>
<div *ngIf="panelId == 3">
<p>Tfs1, transcription elongation factor TFIIS, has an impact on chromosome segregation affected by pka1 deletion in Schizosaccharomyces pombe.</p>



<p>Originally published in <a href='https://doi.org/10.1007/s00294-023-01268-0'>Curr Genet</a>.</p>

</div>
<div *ngIf="panelId == 4">
<p>RNA polymerase II CTD interactome with 3’ processing and termination factors in fission yeast and its impact on phosphate homeostasis.</p>



<p>Originally published in <a href='https://doi.org/10.1073/pnas.1810711115'>Proc Natl Acad Sci U S A</a>.</p>

</div>
<div *ngIf="panelId == 5">
<p>Duf89 abets lncRNA control of fission yeast phosphate homeostasis via its antagonism of precocious lncRNA transcription termination.</p>



<p>Originally published in <a href='https://doi.org/10.1261/rna.079595.123'>RNA</a>.</p>

</div>
<div *ngIf="panelId == 6">
<p>Fission yeast Dis1 is an unconventional TOG/XMAP215 that induces microtubule catastrophe to drive chromosome pulling.</p>



<p>Originally published in <a href='https://doi.org/10.1038/s42003-022-04271-2'>Commun Biol</a>.</p>

</div>
<div *ngIf="panelId == 7">
<p>Inorganic polyphosphate abets silencing of a sub-telomeric gene cluster in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.17912/micropub.biology.000744'>MicroPubl Biol</a>.</p>

</div>
<div *ngIf="panelId == 8">
<p>Multiple polarity kinases inhibit phase separation of F-BAR protein Cdc15 and antagonize cytokinetic ring assembly in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.7554/eLife.83062'>Elife</a>.</p>

</div>
<div *ngIf="panelId == 9">
<p>A scaffold lncRNA shapes the mitosis to meiosis switch.</p>



<p>Originally published in <a href='https://doi.org/10.1038/s41467-021-21032-7'>Nat Commun</a>.</p>

</div>
<div *ngIf="panelId == 10">
<p>The SAGA histone acetyltransferase module targets SMC5/6 to specific genes.</p>



<p>Originally published in <a href='https://doi.org/10.1186/s13072-023-00480-z'>Epigenetics Chromatin</a>.</p>

</div>
<div *ngIf="panelId == 11">
<p>Protein S-palmitoylation regulates different stages of meiosis in Schizosaccharomyces pombe.</p>



<p>Originally published in <a href='https://doi.org/10.26508/lsa.202201755'>Life Science Alliance</a>.</p>

</div>
<div *ngIf="panelId == 12">
<p>Microtubule-mitochondrial attachment facilitates cell division symmetry and mitochondrial partitioning in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1242/jcs.260705'>Journal of cell Science</a>.</p>

</div>
<div *ngIf="panelId == 13">
<p>Tschimganine has different targets for chronological lifespan extension and growth inhibition in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1093/bbb/zbac051'>Bioscience, Biotechnology, and Biochemistry</a>.</p>

</div>
<div *ngIf="panelId == 14">
<p>Contractile ring structure at nanoscale resolution. Image from <a href="https://elifesciences.org/articles/28865#video1">eLife 2017;6:e28865</a>, used under <a href="https://creativecommons.org/licenses/by/4.0/">CC-BY</a> license terms.</p>

</div>
<div *ngIf="panelId == 15">
<p>Molecular model of fission yeast centrosome assembly determined by superresolution imaging.</p>



<p>Originally published in <a href="http://jcb.rupress.org/content/216/8/2409">Journal of Cell Biology</a>.</p>

</div>
<div *ngIf="panelId == 16">
<p>Structural Basis for Shelterin Bridge Assembly.</p>



<p>Originally published in <a href="http://www.cell.com/molecular-cell/fulltext/S1097-2765%2817%2930809-2">Molecular Cell</a>.</p>

</div>
<div *ngIf="panelId == 17">
<p>Study shows second ssDNA capture by the SMC complex during establishment of sister chromatid cohesion.</p>



<p>Originally published in <a href='http://www.cell.com/cell/fulltext/S0092-8674(17)31501-5'>Cell</a>.</p>

</div>
<div *ngIf="panelId == 18">
<p>Model illustrating collaborative inward force generation by kinesin-14s.</p>



<p>Originally published in <a href='http://jcs.biologists.org/content/131/1/jcs210740.long'>Journal of Cell Science</a>.</p>

</div>
<div *ngIf="panelId == 19">
<p>CAGE-defined Transcription Start Sites across 5 different conditions.</p>



<p>Originally published in the <a href='https://academic.oup.com/nar/article/47/4/1671/5253058'>Nucleic Acids Research</a>.</p>

</div>
<div *ngIf="panelId == 20">
<p>The ancient claudin Dni2 facilitates cell fusion at the shmoo tip.</p>



<p>Originally published in <a href='https://link.springer.com/article/10.1007%2Fs00018-017-2709-4'>Cellular and Molecular Life Sciences</a>.</p>

</div>
<div *ngIf="panelId == 21">
<p>Graph-based visualization of Hi-C datasets.</p>



<p>Originally published in <a href='https://bmcresnotes.biomedcentral.com/articles/10.1186/s13104-018-3507-2'>BMC Research Notes</a>.</p>

</div>
<div *ngIf="panelId == 22">
<p>A unified model for cell size control.</p>



<p>Originally published in <a href='http://jcb.rupress.org/content/217/5/1589'>Journal of Cell Biology</a>. Image from a <a href='http://jcb.rupress.org/content/217/5/1559'>Commentary</a> by Gerganova and Martin.</p>

</div>
<div *ngIf="panelId == 23">
<p>A Cdk9-PP1 switch for RNA polymerase II elongation-termination.</p>



<p>Originally published in <a href='https://www.nature.com/articles/s41586-018-0214-z'>Nature</a>.</p>

</div>
<div *ngIf="panelId == 24">
<p>Quantitative phosphoproteomics reveals the signaling dynamics of cell-cycle kinases; ~10K phosphorylation sites now in PomBase.</p>



<p>Originally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(18)30946-X'>Cell Reports</a>.</p>

</div>
<div *ngIf="panelId == 25">
<p>Sharpening the anaphase switch.</p>



<p>Originally published in <a href='https://linkinghub.elsevier.com/retrieve/pii/S2211-1247(17)30069-4'>Cell Reports</a>.</p>

</div>
<div *ngIf="panelId == 26">
<p>Microtubule depolymerization in scd1Δ cells leads to PORTLI growth.</p>



<p>Originally published in the <a href='http://jcs.biologists.org/content/131/14/jcs216580'>Journal of Cell Science</a>.</p>

</div>
<div *ngIf="panelId == 27">
<p>Essential genes for quiescence to cell cycle switching.</p>



<p>Originally published in <a href='http://advances.sciencemag.org/content/4/8/eaat5685'>Science Advances</a>.</p>

</div>
<div *ngIf="panelId == 28">
<p>Genome-wide phenotypic analysis of chromate toxicity.</p>



<p>Originally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1007595'>PLoS Genetics</a>.</p>

</div>
<div *ngIf="panelId == 29">
<p><em>wtf</em> genes in poison–antidote meiotic drive and its suppression.</p>



<p>Originally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1007836'>PLoS Genetics</a>.</p>

</div>
<div *ngIf="panelId == 30">
<p>RNA-binding proteins distinguish between similar sequence motifs to promote targeted deadenylation by Ccr4-Not.</p>



<p>Originally published in <a href='https://elifesciences.org/articles/40670'>eLife</a>. Image from <a href="https://elifesciences.org/articles/40670#fig7">eLife 2019;8:e40670</a>, used under <a href="https://creativecommons.org/licenses/by/4.0/">CC-BY</a> license terms.</p>

</div>
<div *ngIf="panelId == 31">
<p>Cryo-EM structure of kinesin-5-microtubule complex reveals a distinct binding footprint and mechanism of drug resistance.</p>



<p>Originally published in <a href='https://www.sciencedirect.com/science/article/pii/S0022283619300105'>JMB</a>.</p>

</div>
<div *ngIf="panelId == 32">
<p>Centromere DNA destabilizes H3 nucleosomes to promote CENP-A deposition during the cell cycle.</p>



<p>Originally published in <a href='https://www.sciencedirect.com/science/article/pii/S0960982218314167'>Current Biology</a>.</p>

</div>
<div *ngIf="panelId == 33">
<p>DNA topoisomerase II phosphorylation in fission yeast reveals anticancer mechanism.</p>



<p>Originally published in <a href='http://www.jbc.org/content/early/2019/01/11/jbc.RA118.004955.long'>Journal of Biological Chemistry</a>.</p>

</div>
<div *ngIf="panelId == 34">
<p>Zinc transporters belonging to the Cation Diffusion Facilitator (CDF) family have complementary roles in transporting zinc out of the cytosol.</p>



<p>Originally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1007262'>PLoS Genetics</a>.</p>

</div>
<div *ngIf="panelId == 35">
<p>Systematic analysis reveals the prevalence and principles of bypassable gene essentiality.</p>



<p>Originally published in <a href='https://www.nature.com/articles/s41467-019-08928-1'>Nature Communications</a>.</p>

</div>
<div *ngIf="panelId == 36">
<p>Swi5-Sfr1 stimulates Rad51 recombinase filament assembly by modulating Rad51 dissociation.</p>



<p>Originally published in <a href='https://www.pnas.org/content/115/43/E10059.long'>PNAS</a>.</p>

</div>
<div *ngIf="panelId == 37">
<p>Specific primary septum detection reveals septum and cleavage furrow ingression during early anaphase independent of mitosis completion.</p>



<p>Originally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1007388'>PLoS Genetics</a>.</p>

</div>
<div *ngIf="panelId == 38">
<p>Paxillin-mediated recruitment of calcineurin to the contractile ring is required for cytokinesis.</p>



<p>Originally published in <a href='https://www.sciencedirect.com/science/article/pii/S2211124718315079?via%3Dihub'>Cell Reports</a>.</p>

</div>
<div *ngIf="panelId == 39">
<p>Taf12 phosphorylation inhibits sexual differentiation downstream of TORC2.</p>

<p>Originally published in <a href='http://embor.embopress.org/content/18/12/2197.long'>EMBO reports</a>.</p>

</div>
<div *ngIf="panelId == 40">
<p>Unexpected insertion of carrier DNA during CRISPR-Cas9, and “unknowns” remain “unknown”</p>

<p>Originally published in <a href='https://bmcresnotes.biomedcentral.com/articles/10.1186/s13104-019-4228-x'>BMC Research Notes</a>.</p>

</div>
<div *ngIf="panelId == 41">
<p>NDR Kinase Sid2 Drives Anillin-like Mid1 from the Membrane to Promote Cytokinesis.</p>

<p>Originally published in <a href='https://www.cell.com/current-biology/fulltext/S0960-9822(19)30134-4'>Current Biology</a>.</p>

</div>
<div *ngIf="panelId == 42">
<p>Set7, a histone methyltransferase for the uncharted histone H3-37 mark.</p>

<p>Originally published in <a href='https://www.cell.com/structure/fulltext/S0969-2126(19)30011-5'>Structure</a>.</p>

</div>
<div *ngIf="panelId == 43">
<p>Fission yeast contains separate CC- and A-adding tRNA nucleotidyltransferases.</p>

<p>Originally published in <a href='https://www.sciencedirect.com/science/article/pii/S0006291X18325634'>Biochemical and Biophysical Research Communications</a>.</p>

</div>
<div *ngIf="panelId == 44">
<p>Distinct roles of myosin-II isoforms in cytokinesis under normal and stressed conditions.</p>

<p>Originally published in <a href='https://doi.org/10.1016/j.isci.2019.03.014'>iScience</a>.</p>

</div>
<div *ngIf="panelId == 45">
<p>Previously uncharacterized protein, Ync13, has a role during late stages of cytokinesis.</p>

<p>Originally published in <a href='https://www.molbiolcell.org/doi/full/10.1091/mbc.E18-04-0225'>Molecular Biology of the Cell</a>.</p>

</div>
<div *ngIf="panelId == 46">
<p>Simple procedure for the generation of temperature-sensitive mutations with error-prone PCR.</p>

<p>Originally published in <a href='https://www.tandfonline.com/doi/abs/10.1080/09168451.2019.1611414'>Bioscience, Biotechnology, and Biochemistry</a>.</p>

</div>
<div *ngIf="panelId == 47">
<p>Isolation of single amino acid t-s mutants in condensin hinge domain.</p>

<p>Originally published in <a href='https://www.g3journal.org/content/9/5/1777.long'>G3: Genes, Genomes, Genetics</a>.</p>

</div>
<div *ngIf="panelId == 48">
<p>Suppressor screening reveals common kleisin-hinge interaction in condensin and cohesin.</p>

<p>Originally published in <a href='https://www.pnas.org/content/early/2019/05/09/1902699116.long'>PNAS</a>.</p>

</div>
<div *ngIf="panelId == 49">
<p>Sequencing of suppressor DNA mixtures identifies pathways that compensate for chromosome segregation defects.</p>

<p>Originally published in <a href='http://www.g3journal.org/cgi/pmidlookup?view=long&amp;pmid=29352077'>G3: Genes, Genomes, Genetics</a>.</p>

</div>
<div *ngIf="panelId == 50">
<p>A. Prevailing ring model vs. B. hold and release model; Suppressor mutation analysis combined with 3D modeling proposes a different mechanism for cohesin.</p>

<p>Originally published in <a href='https://www.pnas.org/content/115/21/E4833.long'>PNAS</a>.</p>

</div>
<div *ngIf="panelId == 51">
<p>Fission yeast TRP channel Pkd2p localizes to the cleavage furrow during cytokinesis.</p>

<p>Originally published in <a href='https://www.molbiolcell.org/doi/abs/10.1091/mbc.E18-04-0270'>Molecular Biology of the Cell</a>.</p>

</div>
<div *ngIf="panelId == 52">
<p>The J-domain co-chaperone Rsp1 interacts with Mto1 to organize non-centrosomal microtubule assembly.</p>

<p>Originally published in <a href='https://www.molbiolcell.org/doi/10.1091/mbc.E18-05-0279'>Molecular Biology of the Cell</a>.</p>

</div>
<div *ngIf="panelId == 53">
<p>The PCNA unloader Elg1 promotes recombination at collapsed replication forks.</p>

<p>Originally published in <a href='https://elifesciences.org/articles/47277'>eLife</a>. Image from <a href="https://elifesciences.org/articles/47277#fig7">eLife 2019;8:e47277</a>, used under <a href="https://creativecommons.org/licenses/by/4.0/">CC-BY</a> license terms.</p>

</div>
<div *ngIf="panelId == 54">
<p>MAPs and Microtubule Dynamics Balance with the Cut7/Kinesin-5 Motor for Mitotic Spindle Assembly.</p>

<p>Originally published in <a href='https://www.g3journal.org/content/9/1/269.long'>G3: Genes, Genomes, Genetics</a>.</p>

</div>
<div *ngIf="panelId == 55">
<p>Nuclear “blebbing” in Lem2 mutants from “Nuclear membrane protein Lem2 regulates nuclear size through membrane flow”.</p>

<p>Originally published in <a href='https://www.nature.com/articles/s41467-019-09623-x'>Nature Communications</a>.</p>

</div>
<div *ngIf="panelId == 56">
<p>Coordinated Roles of the Putative Ceramide-Conjugation Protein, Cwh43, and a Mn 2+ -Transporting, P-Type ATPase, Pmr1.</p>

<p>Originally published in <a href='https://www.g3journal.org/content/early/2019/06/14/g3.119.400281.long'>G3: Genes, Genomes, Genetics</a>.</p>

</div>
<div *ngIf="panelId == 57">
<p>Epe1 associates with the SAGA complex to promote transcription of repeats for heterochromatin assembly.</p>

<p>Originally published in <a href='http://genesdev.cshlp.org/content/33/1-2/116.long'>Genes &amp; Development</a>.</p>

</div>
<div *ngIf="panelId == 58">
<p>The F-BAR Domain of Rga7 Relies on a Cooperative Mechanism of Membrane Binding with Rng10.</p>

<p>Originally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(19)30149-4'>Cell Reports</a>.</p>

</div>
<div *ngIf="panelId == 59">
<p>The fitness landscape of the fission yeast genome.</p>



<p>Originally published in <a href='https://doi.org/10.1093/molbev/msz113'>Mol Biol Evol</a>.</p>



<p><a href='/jbrowse/?tracks=DNA%20sequence%2CPomBase%20forward%20strand%20features%2CPomBase%20reverse%20strand%20features%2CConservation%20of%20sites%20over%20the%20phylogeny%20of%20S.%20pombe%E2%80%9A%20S.%20octosporus%E2%80%9A%20S.%20japonicus%E2%80%9A%20and%20S.%20cryophilus%20(higher%20values%20are%20associated%20with%20higher%20degree%20of%20conservation)-%20Grech%20et%20al.%20(2019)%2CHDE%20units%E2%80%9A%20continuous%20runs%20of%20states%20S1-S3%20-%20Grech%20et%20al.%20(2019)%2CTransposon%20insertion%20sites%20-%20Grech%20et%20al.%20(2019)%2CTransposon%20insertion%20sites%20smoothed%20to%20states%20S1-S5%20(lower%20states%20have%20fewer%20insertions)%20-%20Grech%20et%20al.%20(2019)&amp;loc=III%3A804712..899614&amp;highlight='>View datasets in the genome browser …</a></p>

</div>
<div *ngIf="panelId == 60">
<p>A Cdc42 GEF, Gef1, organizes F-BAR Cdc15 along the actomyosin ring.</p>

<p>Originally published in <a href='https://jcs.biologists.org/content/132/5/jcs223776.long'>Journal of Cell Science</a>.</p>

</div>
<div *ngIf="panelId == 61">
<p>Abrogation of glucosidase I-mediated glycoprotein deglucosylation results in a sick phenotype in fission yeasts: Model for the human MOGS-CDG disorder.</p>

<p>Originally published in <a href='http://www.jbc.org/content/293/52/19957.long'>Journal of Biological Chemistry</a>.</p>

</div>
<div *ngIf="panelId == 62">
<p>Factors affecting template switch recombination associated with restarted DNA replication.</p>

<p>Originally published in <a href='https://elifesciences.org/articles/41697'>eLife</a>. Image from <a href="https://elifesciences.org/articles/41697#fig7">eLife 2019;8:e41697</a>, used under <a href="https://creativecommons.org/licenses/by/4.0/">CC-BY</a> license terms.</p>

</div>
<div *ngIf="panelId == 63">
<p>Asc1 stabilizes the interaction between eIF3a and Rps0A/uS2 for protein synthesis.</p>

<p>Originally published in <a href='https://mcb.asm.org/content/early/2019/07/03/MCB.00161-19.long'>Molecular and Cellular Biology</a>.</p>

</div>
<div *ngIf="panelId == 64">
<p>H3K14 ubiquitylation promotes H3K9 methylation for heterochromatin assembly.</p>

<p>Originally published in <a href='https://www.embopress.org/doi/full/10.15252/embr.201948111'>EMBO reports</a>.</p>

</div>
<div *ngIf="panelId == 65">
<p>Lem2 is retained at the nuclear envelope through its interaction with Bqt4 in fission yeast. LEM domain directly binds to DNA.</p>

<p>Originally published in <a href='https://onlinelibrary.wiley.com/doi/full/10.1111/gtc.12557'>Genes Cells</a>.</p>

</div>
<div *ngIf="panelId == 66">
<p>A new TF binding site (Loz1 response element) for zinc repression.</p>

<p>Originally published in <a href='https://onlinelibrary.wiley.com/doi/abs/10.1111/mmi.14384'>Molecular Microbiology</a>.</p>

</div>
<div *ngIf="panelId == 67">
<p>Kinetochore-mediated outward force promotes spindle pole separation.</p>

<p>Originally published in <a href='https://www.molbiolcell.org/doi/10.1091/mbc.E19-07-0366'>Molecular Biology of the Cell</a>.</p>

</div>
<div *ngIf="panelId == 68">
<p>Capping Protein Insulates Arp2/3-Assembled Actin Patches from Formins.</p>

<p>Originally published in <a href='https://www.sciencedirect.com/science/article/pii/S0960982219310127'>Current Biology</a>.</p>

</div>
<div *ngIf="panelId == 69">
<p>Microtubules and Alp7-Alp14 (TACC-TOG) reposition meiotic chromosomes.</p>

<p>Originally published in <a href='https://www.nature.com/articles/ncb2782'>Nature Cell Biology</a>.</p>

</div>
<div *ngIf="panelId == 70">
<p>F-BAR Cdc15 Promotes Cdc42 Activation During Cytokinesis and Cell Polarization in <em>Schizosaccharomyces pombe</em>.</p>

<p>Originally published in <a href='https://www.genetics.org/content/early/2019/10/07/genetics.119.302649.long'>Genetics</a>.</p>

</div>
<div *ngIf="panelId == 71">
<p>Tel2-Tti1-Tti2 complex destabilization eliminates Rad3-ATR kinase signaling in the DNA replication checkpoint and leads to telomere shortening.</p>

<p>Originally published in <a href='https://mcb.asm.org/content/39/20/e00175-19'>Molecular and Cell Biology</a>.</p>

</div>
<div *ngIf="panelId == 72">
<p>CLASP promotes microtubule bundling in metaphase spindle independently of Ase1.</p>

<p>Originally published in <a href='https://bio.biologists.org/content/8/10/bio045716'>Biology Open</a>.</p>

</div>
<div *ngIf="panelId == 73">
<p>The Bub1-TPR domain interacts directly with Mad3 to generate robust spindle checkpoint arrest.</p>

<p>Originally published in <a href='https://www.cell.com/current-biology/fulltext/S0960-9822(19)30704-3'>Current Biology</a>.</p>

</div>
<div *ngIf="panelId == 74">
<p>Effects of the microtubule nucleator Mto1 on chromosome movement, DNA repair and sister chromatid cohesion.</p>

<p>Originally published in <a href='https://www.molbiolcell.org/doi/10.1091/mbc.E19-05-0301'>Molecular Biology of the Cell</a>.</p>

</div>
<div *ngIf="panelId == 75">
<p>Association of mitochondria with microtubules inhibits mitochondrial fission.</p>

<p>Originally published in <a href='http://www.jbc.org/content/294/10/3385'>Journal of Biological Chemistry</a>.</p>

</div>
<div *ngIf="panelId == 76">
<p>A highly modular toolbox to introduce heterologous sequences and includes antibiotic resistance markers, promoters, fluorescent tags, and terminators.</p>

<p>Originally published in <a href='https://jcs.biologists.org/content/early/2019/11/29/jcs.240754.long'>Journal of Cell Science</a>.</p>

</div>
<div *ngIf="panelId == 77">
<p>Role for cleavage and polyadenylation factor (CPF) in RNAi-independent heterochromatin domain assembly.</p>

<p>Originally published in <a href='https://www.sciencedirect.com/science/article/pii/S2211124719307545?via%3Dihub'>Cell Reports</a>.</p>

</div>
<div *ngIf="panelId == 78">
<p>Gcn5-mediated acetylation at MBF-regulated promoters induces the G1/S transcriptional wave.</p>

<p>Originally published in <a href='https://academic.oup.com/nar/article/47/16/8439/5526704'>Nucleic Acids Research</a>.</p>

</div>
<div *ngIf="panelId == 79">
<p>Mkt1 is required for RNAi-mediated silencing and establishment of heterochromatin in fission yeast.</p>

<p>Originally published in <a href='https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkz1157/5673137'>Nucleic Acids Research</a>.</p>

</div>
<div *ngIf="panelId == 80">
<p>Physical basis for long-distance communication along meiotic chromosomes.</p>

<p>Originally published in <a href='https://www.pnas.org/content/115/40/E9333.long'>PNAS</a>.</p>

</div>
<div *ngIf="panelId == 81">
<p>A novel interplay between GEFs orchestrates Cdc42 activity during cell polarity and cytokinesis in fission yeast.</p>

<p>Originally published in <a href='https://jcs.biologists.org/content/132/23/jcs236018.long'>Journal of Cell Science</a>.</p>

</div>
<div *ngIf="panelId == 82">
<p>Fission Yeast NDR/LATS Kinase Orb6 Regulates Exocytosis via Phosphorylation of the Exocyst Complex.</p>

<p>Originally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(19)30036-1'>Cell Reports</a>.</p>

</div>
<div *ngIf="panelId == 83">
<p>Structures of the Mitochondrial CDP-DAG Synthase Tam41 Suggest a Potential Lipid Substrate Pathway from Membrane to the Active Site.</p>

<p>Originally published in <a href='https://www.cell.com/structure/fulltext/S0969-2126(19)30162-5'>Structure</a>.</p>

</div>
<div *ngIf="panelId == 84">
<p>Atg38-Atg8 Interaction in Fission Yeast Establishes a Positive Feedback Loop to Promote Autophagy</p>

<p>Originally published in <a href='https://www.tandfonline.com/doi/full/10.1080/15548627.2020.1713644'>Autophagy</a>.</p>

</div>
<div *ngIf="panelId == 85">
<p>Plasma Membrane Furrows Control Plasticity of ER-PM Contacts.</p>

<p>Originally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(19)31763-2'>Cell Reports</a>.</p>

</div>
<div *ngIf="panelId == 86">
<p>Chromatin remodeler Fft3 plays a dual role at blocked DNA replication forks.</p>

<p>Originally published in <a href="https://www.life-science-alliance.org/content/2/5/e201900433">Life Science Alliance</a>.</p>

</div>
<div *ngIf="panelId == 87">
<p>Cortical tethering of mitochondria by the anchor protein Mcp5 enables uniparental inheritance.</p>

<p>Originally published in <a href='https://rupress.org/jcb/article-lookup/doi/10.1083/jcb.201901108'>Journal of Cell Biology</a>.</p>

</div>
<div *ngIf="panelId == 88">
<p>Casein kinase 2 regulates telomere protein complex formation through Rap1 phosphorylation.</p>

<p>Originally published in <a href='https://academic.oup.com/nar/article/47/13/6871/5498753'>Nucleic Acids Research</a>.</p>

</div>
<div *ngIf="panelId == 89">
<p>Checkpoint Regulation of Nuclear Tos4 Defines S Phase Arrest in Fission Yeast.</p>

<p>Originally published in <a href='https://www.g3journal.org/content/10/1/255'>G3: Genes, Genomes, Genetics</a>.</p>

</div>
<div *ngIf="panelId == 90">
<p>The NuA4 acetyltransferase and histone H4 acetylation promote replication recovery after topoisomerase I-poisoning.</p>

<p>Originally published in <a href='https://epigeneticsandchromatin.biomedcentral.com/articles/10.1186/s13072-019-0271-z'>Epigenetics &amp; Chromatin</a>.</p>

</div>
<div *ngIf="panelId == 91">
<p>Dramatically diverse ’Schizosaccharomyces pombe wtf* meiotic drivers all display high gamete-killing efficiency.</p>

<p>Originally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1008350'>PLoS Genetics</a>.</p>

</div>
<div *ngIf="panelId == 92">
<p>Genetic investigation of formaldehyde-induced DNA damage response in <em>Schizosaccharomyces pombe</em>.</p>

<p>Originally published in <a href='https://link.springer.com/article/10.1007%2Fs00294-020-01057-z'>Current Genetics</a>.</p>

</div>
<div *ngIf="panelId == 93">
<p>Maf1-dependent transcriptional regulation of tRNAs prevents genomic instability and is associated with extended lifespan.</p>

<p>Originally published in <a href='https://onlinelibrary.wiley.com/doi/full/10.1111/acel.13068'>Aging Cell</a>.</p>

</div>
<div *ngIf="panelId == 94">
<p>Cooperative interactions facilitate stimulation of Rad51 by the Swi5-Sfr1 auxiliary factor complex.</p>

<p>Originally published in <a href='https://elifesciences.org/articles/52566'>eLife</a>. Image: left panel from <a href="https://elifesciences.org/articles/52566#fig7">eLife 2020;9:52566</a>, used under <a href="https://creativecommons.org/licenses/by/4.0/">CC-BY</a> license terms; right panel B. Argunhan pers. comm.</p>

</div>
<div *ngIf="panelId == 95">
<p>Chaperone-Facilitated Aggregation of Thermo-Sensitive Proteins Shields Them from Degradation during Heat Stress.</p>

<p>Originally published in <a href="http://dx.doi.org/10.1016/j.celrep.2020.01.077">Cell Reports</a>.</p>

</div>
<div *ngIf="panelId == 96">
<p>Ctp1 protein-DNA filaments promote DNA bridging and DNA double-strand break repair.</p>

<p>Originally published in <a href="https://www.jbc.org/content/294/9/3312">Journal of Biological Chemistry</a>.</p>

</div>
<div *ngIf="panelId == 97">
<p>Chromosome-associated RNA-protein complexes promote pairing of homologous chromosomes during meiosis.</p>

<p>Originally published in <a href="https://www.nature.com/articles/s41467-019-13609-0">Nature Communications</a>.</p>

</div>
<div *ngIf="panelId == 98">
<p>Mutations in a single signaling pathway allow cell growth in heavy water.</p>

<p>Image reprinted with permission from <a href='https://pubs.acs.org/doi/full/10.1021/acssynbio.9b00376'>ACS Synthetic Biology 2020 9 (4), 733-748</a>. Copyright 2020 American Chemical Society.</p>

</div>
<div *ngIf="panelId == 99">
<p>Pyruvate kinase variant of fission yeast tunes carbon metabolism, cell regulation, growth and stress resistance.</p>

<p>Originally published in <a href='https://www.embopress.org/doi/full/10.15252/msb.20199270'>Molecular Systems Biology</a>.</p>

</div>
<div *ngIf="panelId == 100">
<p>Conserved roles of chromatin remodellers in cohesin loading onto chromatin.</p>

<p>Originally published in <a href='https://link.springer.com/article/10.1007%2Fs00294-020-01075-x'>Current Genetics</a>.</p>

</div>
<div *ngIf="panelId == 101">
<p>Different Functionality of Cdc20 Binding Sites within the Mitotic Checkpoint Complex.</p>

<p>Originally published in <a href='https://doi.org/10.1016/j.cub.2017.03.007'>Curr.Biol.</a></p>

</div>
<div *ngIf="panelId == 102">
<p>Active replication checkpoint drives genome instability in fission yeast mcm4 mutant.</p>

<p>Originally published in <a href='https://mcb.asm.org/content/early/2020/04/21/MCB.00033-20'>Molecular and Cellular Biology</a>.</p>

</div>
<div *ngIf="panelId == 103">
<p>Spt5 phosphorylation and the Rtf1 Plus3 domain promote Rtf1 function through distinct mechanisms.</p>

<p>Originally published in <a href='https://mcb.asm.org/content/early/2020/05/01/MCB.00150-20'>Molecular and Cellular Biology</a>.</p>

</div>
<div *ngIf="panelId == 104">
<p>Glucose limitation and pka1 deletion rescue aberrant mitotic spindle formation induced by Mal3 overexpression in Schizosaccharomyces pombe.</p>

<p>Originally published in <a href='https://www.tandfonline.com/doi/full/10.1080/09168451.2020.1763157'>Bioscience, Biotechnology, and Biochemistry</a>.</p>

</div>
<div *ngIf="panelId == 105">
<p>Two Auxiliary Factors Promote Dmc1-driven DNA Strand Exchange via Stepwise Mechanisms .</p>

<p>Originally published in <a href='https://www.pnas.org/content/117/22/12062'>PNAS</a>.</p>

</div>
<div *ngIf="panelId == 106">
<p>DYRK kinase Pom1 drives F-BAR protein Cdc15 from the membrane to promote medial division.</p>

<p>Originally published in <a href='https://doi.org/10.1091/mbc.E20-01-0026'>Molecular Biology of the Cell</a>.</p>

</div>
<div *ngIf="panelId == 107">
<p>Nutrient-dependent control of RNA polymerase II elongation rate regulates specific gene expression programs by alternative polyadenylation.</p>

<p>Originally published in <a href='http://genesdev.cshlp.org/content/early/2020/06/03/gad.337212.120'>Genes &amp; Development</a>.</p>

</div>
<div *ngIf="panelId == 108">
<p>DNA replication machinery prevents Rad52-dependent single-strand annealing that leads to gross chromosomal rearrangements at centromeres.</p>

<p>Originally published in <a href='https://www.nature.com/articles/s42003-020-0934-0'>Communications Biology</a>.</p>

</div>
<div *ngIf="panelId == 109">
<p>Nuclear Envelope Attachment of Telomeres Limits TERRA and Telomeric Rearrangements in Quiescent Fission Yeast Cells.</p>

<p>Originally published in <a href='https://academic.oup.com/nar/article/48/6/3029/5715813'>Nucleic Acids Research</a>.</p>

</div>
<div *ngIf="panelId == 110">
<p>Cdk9 and H2Bub1 signal to Clr6-CII/Rpd3S to suppress aberrant antisense transcription.</p>

<p>Originally published in <a href='https://academic.oup.com/nar/article/48/13/7154/5851387'>Nucleic Acids Research</a>.</p>

</div>
<div *ngIf="panelId == 111">
<p>The phosphatase inhibitor Sds23 regulates cell division symmetry in fission yeast.</p>

<p>Originally published in <a href='https://www.molbiolcell.org/doi/full/10.1091/mbc.E19-05-0254'>Molecular Biology of the Cell</a>.</p>

</div>
<div *ngIf="panelId == 112">
<p>A role of the Nse4 kleisin and Nse1/Nse3 KITE subunits in the ATPase cycle of SMC5/6.</p>

<p>Originally published in <a href='https://www.nature.com/articles/s41598-020-66647-w'>Scientific Reports</a>.</p>

</div>
<div *ngIf="panelId == 113">
<p>Sterol biosensor reveals LAM-family Ltc1-dependent sterol flow to endosomes upon Arp2/3 inhibition.</p>

<p>Originally published in <a href='https://rupress.org/jcb/article/219/6/e202001147/151694/Sterol-biosensor-reveals-LAM-family-Ltc1-dependent'>Journal of Cell Biology</a>.</p>

</div>
<div *ngIf="panelId == 114">
<p>A UPR-Induced Soluble ER-Phagy Receptor Acts with VAPs to Confer ER Stress Resistance.</p>

<p>Originally published in <a href='https://www.sciencedirect.com/science/article/abs/pii/S1097276520305104?via%3Dihub'>Molecular Cell</a>.</p>

</div>
<div *ngIf="panelId == 115">
<p>CRL4Cdt2 ubiquitin ligase regulates Dna2 and Rad16 (XPF) nucleases by targeting Pxd1 for degradation.</p>



<p>Originally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1008933'>PLoS Genetics</a>.</p>

</div>
<div *ngIf="panelId == 116">
<p>Dense Transposon Integration Reveals Essential Cleavage and Polyadenylation Factors Promote Heterochromatin Formation.</p>

<p>Originally published in <a href="http://dx.doi.org/10.1016/j.celrep.2020.01.094">Cell Reports</a>.</p>

</div>
<div *ngIf="panelId == 117">
<p>Closed mitosis requires local disassembly of the nuclear envelope.</p>

<p>Originally published in <a href='https://www.nature.com/articles/s41586-020-2648-3'>Nature</a>.</p>

</div>
<div *ngIf="panelId == 118">
<p>Splicing Defects of the Profilin Gene Alter Actin Dynamics in an S. pombe SMN Mutant.</p>

<p>Originally published in <a href='http://dx.doi.org/10.1016/j.isci.2019.100809'>iScience</a>.</p>

</div>
<div *ngIf="panelId == 119">
<p>Atypical meiosis can be adaptive in outcrossed Schizosaccharomyces pombe due to wtf meiotic drivers.</p>

<p>Originally published in <a href='https://elifesciences.org/articles/57936'>eLife</a>. Image from <a href="https://elifesciences.org/articles/57936#fig7">eLife 2020;9:e57936</a>, used under <a href="https://creativecommons.org/licenses/by/4.0/">CC-BY</a> license terms.</p>

</div>
<div *ngIf="panelId == 120">
<p>Time-lapse single-cell transcriptomics reveals modulation of histone H3 for dormancy breaking in fission yeast.</p>

<p>Originally published in <a href='https://www.nature.com/articles/s41467-020-15060-y'>Nature Communications</a>.</p>

</div>
<div *ngIf="panelId == 121">
<p>Condensin locates at transcriptional termination sites in mitosis, possibly releasing mitotic transcripts.</p>

<p>Originally published in <a href='https://royalsocietypublishing.org/doi/10.1098/rsob.190125'>Open Biology</a>.</p>

</div>
<div *ngIf="panelId == 122">
<p>Atg1 kinase in fission yeast is activated by Atg11-mediated dimerization and cis-autophosphorylation.</p>

<p>Originally published in <a href='https://elifesciences.org/articles/58073'>eLife</a>. Image from <a href="https://elifesciences.org/articles/58073#fig4">eLife 2020;9:e58073</a>, used under <a href="https://creativecommons.org/licenses/by/4.0/">CC-BY</a> license terms.</p>

</div>
<div *ngIf="panelId == 123">
<p>The Catalytic-Dependent and -Independent Roles of Lsd1 and Lsd2 Lysine Demethylases in Heterochromatin Formation in <em>Schizosaccharomyces pombe</em>.</p>

<p>Originally published in <a href='https://www.mdpi.com/2073-4409/9/4/955'>Cells</a>.</p>

</div>
<div *ngIf="panelId == 124">
<p>Removal of centrosomal PP1 by NIMA kinase unlocks the MPF feedback loop to promote mitotic commitment in S. pombe.</p>

<p>Originally published in <a href='https://www.cell.com/current-biology/fulltext/S0960-9822(12)01512-6?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS0960982212015126%3Fshowall%3Dtrue'>Current Biology</a>.</p>

</div>
<div *ngIf="panelId == 125">
<p>Requirement of PP2A-B56 Par1 for the Stabilization of the CDK Inhibitor Rum1 and Activation of APC/C Ste9 during Pre-Start G1 in S. pombe.</p>

<p>Originally published in <a href='https://www.cell.com/iscience/fulltext/S2589-0042(20)30248-0?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS2589004220302480%3Fshowall%3Dtrue'>iScience</a>.</p>

</div>
<div *ngIf="panelId == 126">
<p>A Redox-Sensitive Thiol in Wis1 Modulates the Fission Yeast Mitogen-Activated Protein Kinase Response to H2O2 and Is the Target of a Small Molecule.</p>

<p>Originally published in <a href='https://mcb.asm.org/content/40/7/e00346-19'>Molecular and Cellular Biology</a>.</p>

</div>
<div *ngIf="panelId == 127">
<p>RNA-Binding Protein Rnc1 Regulates Cell Length at Division and Acute Stress Response in Fission Yeast through Negative Feedback Modulation of the Stress-Activated Mitogen-Activated Protein Kinase Pathway.</p>

<p>Originally published in <a href='https://mbio.asm.org/content/11/1/e02815-19'>mBio</a>.</p>

</div>
<div *ngIf="panelId == 128">
<p>Golgi localization of glycosyltransferases requires Gpp74p in Schizosaccharomyces pombe.</p>

<p>Originally published in <a href='https://link.springer.com/article/10.1007/s00253-020-10881-9'>Applied Microbiology and Biotechnology</a>.</p>

</div>
<div *ngIf="panelId == 129">
<p>Stress-activated MAPK signalling controls fission yeast actomyosin ring integrity by modulating formin For3 levels.</p>

<p>Originally published in <a href='https://elifesciences.org/articles/57951'>eLife</a>. Image from <a href="https://elifesciences.org/articles/58073#fig5">eLife 2020;9:e57951</a>, used under <a href="https://creativecommons.org/licenses/by/4.0/">CC-BY</a> license terms.</p>

</div>
<div *ngIf="panelId == 130">
<p>The CDK Pef1 and PP4 (Ppe2) oppose each other for regulating cohesin binding to chromosomes.</p>

<p>Originally published in <a href='https://elifesciences.org/articles/50556'>eLife</a>. Image from <a href="https://elifesciences.org/articles/50556#fig8">eLife 2020;9:e50556</a>, used under <a href="https://creativecommons.org/licenses/by/4.0/">CC-BY</a> license terms.</p>

</div>
<div *ngIf="panelId == 131">
<p>A review of genes involved in extension of chronological lifespan, and the identification of 4 new CLS genes.</p>

<p>Originally published in <a href='https://onlinelibrary.wiley.com/doi/10.1111/mmi.14627'>Molecular Microbiology</a>.</p>

</div>
<div *ngIf="panelId == 132">
<p>Fission yeast condensin contributes to interphase chromatin organization and prevents transcription-coupled DNA damage.</p>

<p>Originally published in <a href='https://genomebiology.biomedcentral.com/articles/10.1186/s13059-020-02183-0'>Genome Biology</a>.</p>

</div>
<div *ngIf="panelId == 133">
<p>Elongation/Termination Factor Exchange Mediated by PP1 Phosphatase Orchestrates Transcription Termination.</p>

<p>Originally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(18)31422-0'>Cell Reports</a>.</p>

</div>
<div *ngIf="panelId == 134">
<p>Molecular Mechanism for the Actin-Binding Domain of α-Actinin Ain1 Elucidated by Molecular Dynamics Simulations and Mutagenesis Experiments.</p>

<p>Originally published in <a href='https://pubs.acs.org/doi/full/10.1021/acs.jpcb.0c04623'>The Journal of Physical Chemistry</a>.</p>

</div>
<div *ngIf="panelId == 135">
<p>The nuclear pore primes recombination-dependent DNA synthesis at arrested forks by promoting SUMO removal.</p>

<p>Originally published in <a href='https://www.nature.com/articles/s41467-020-19516-z'>Nature Communications</a>.</p>

</div>
<div *ngIf="panelId == 136">
<p>Selective Nuclear Pore Complex Removal Drives Nuclear Envelope Division in Fission Yeast.</p>

<p>Originally published in <a href='https://linkinghub.elsevier.com/retrieve/pii/S0960982220307533'>Current Biology</a>.</p>

</div>
<div *ngIf="panelId == 137">
<p>High-Throughput Flow Cytometry Combined with Genetic Analysis Brings New Insights into the Understanding of Chromatin Regulation of Cellular Quiescence.</p>

<p>Originally published in <a href='https://www.mdpi.com/1422-0067/21/23/9022/htm'>International Journal of Molecular Sciences</a>.</p>

</div>
<div *ngIf="panelId == 138">
<p>Mitochondrial respiration is required to provide amino acids during fermentative proliferation of fission yeast.</p>

<p>Originally published in <a href='https://www.embopress.org/doi/full/10.15252/embr.202050845'>EMBO Reports</a>.</p>

</div>
<div *ngIf="panelId == 139">
<p>Systematic Target Screening Revealed That Tif302 Could Be an Off-Target of the Antifungal Terbinafine in Fission Yeast.</p>

<p>Originally published in <a href='http://www.biomolther.org/journal/view.html?uid=1272&vmd=Full'>Biomolecules &amp; Therapeutics</a>.</p>

</div>
<div *ngIf="panelId == 140">
<p>Serine catabolism produces ROS, sensitizes cells to actin dysfunction, and suppresses cell growth in fission yeast.</p>

<p>Originally published in <a href='https://www.nature.com/articles/s41429-020-0305-6'>The Journal of Antibiotics</a>.</p>

</div>
<div *ngIf="panelId == 141">
<p>Phosphoproteomics Reveals Novel Targets and Phosphoprotein Networks in Cell Cycle Mediated by Dsk1.</p>

<p>Originally published in <a href='https://pubs.acs.org/doi/10.1021/acs.jproteome.0c00027'>Journal of Proteome Research</a>.</p>

</div>
<div *ngIf="panelId == 142">
<p>Opposite Surfaces of the Cdc15 F-BAR Domain Create a Membrane Platform That Coordinates Cytoskeletal and Signaling Components for Cytokinesis.</p>

<p>Originally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(20)31515-1'>Cell Reports</a>.</p>

</div>
<div *ngIf="panelId == 143">
<p>Atg43 tethers isolation membranes to mitochondria to promote starvation-induced mitophagy in fission yeast.</p>

<p>Originally published in <a href='https://elifesciences.org/articles/61245'>eLife</a>. Image from <a href="https://elifesciences.org/articles/61245#fig7">eLife 2020;9:e61245</a>, used under <a href="https://creativecommons.org/licenses/by/4.0/">CC-BY</a> license terms.</p>

</div>
<div *ngIf="panelId == 144">
<p>The prefoldin complex stabilizes the von Hippel-Lindau protein against aggregation and degradation.</p>

<p>Originally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1009183'>PLoS Genetics</a>.</p>

</div>
<div *ngIf="panelId == 145">
<p>The Fission Yeast RNA-Binding Protein Meu5 Is Involved in Outer Forespore Membrane Breakdown during Spore Formation.</p>

<p>Originally published in <a href='https://www.mdpi.com/2309-608X/6/4/284'>Journal of Fungi</a>.</p>

</div>
<div *ngIf="panelId == 146">
<p>Formation of S. pombe Erh1 homodimer mediates gametogenic gene silencing and meiosis progression.</p>

<p>Originally published in <a href='https://www.nature.com/articles/s41598-020-57872-4'>Scientific Reports</a>.</p>

</div>
<div *ngIf="panelId == 147">
<p>Hypomodified tRNA in evolutionarily distant yeasts can trigger rapid tRNA decay to activate the general amino acid control response, but with different consequences.</p>

<p>Originally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1008893'>PLoS Genetics</a>.</p>

</div>
<div *ngIf="panelId == 148">
<p>The Hsp40 Mas5 Connects Protein Quality Control and the General Stress Response through the Thermo-sensitive Pyp1.</p>

<p>Originally published in <a href='https://linkinghub.elsevier.com/retrieve/pii/S2589-0042(20)30922-6'>iScience</a>.</p>

</div>
<div *ngIf="panelId == 149">
<p>The fission yeast Pin1 peptidyl-prolyl isomerase promotes dissociation of Sty1 MAPK from RNA polymerase II and recruits Ssu72 phosphatase to facilitate oxidative stress induced transcription.</p>

<p>Originally published in <a href='https://academic.oup.com/nar/article/49/2/805/6067400'>Nucleic Acids Research</a>.</p>

</div>
<div *ngIf="panelId == 150">
<p>Multiplexed proteome profiling of carbon source perturbations in two yeast species with SL-SP3-TMT..</p>

<p>Originally published in <a href='https://www.sciencedirect.com/science/article/pii/S1874391919303033'>Journal of Proteomics</a>.</p>

</div>
<div *ngIf="panelId == 151">
<p>Comparison of loop extrusion and diffusion capture as mitotic chromosome formation pathways in fission yeast.</p>

<p>Originally published in <a href='https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkaa1270/6090303'>Nucleic Acids Research</a>.</p>

</div>
<div *ngIf="panelId == 152">
<p>Escape from mitotic catastrophe by actin-dependent nuclear displacement in fission yeast..</p>

<p>Originally published in <a href='https://www.cell.com/iscience/fulltext/S2589-0042(20)31228-1'>iScience</a>.</p>

</div>
<div *ngIf="panelId == 153">
<p>RNA interference is essential for cellular quiescence.</p>

<p>Originally published in <a href='https://science.sciencemag.org/content/354/6313/aah5651.long'>Science</a>.</p>

</div>
<div *ngIf="panelId == 154">
<p>Integrity of a heterochromatic domain ensured by its boundary elements.</p>

<p>Originally published in <a href='https://www.pnas.org/content/117/35/21504'>PNAS</a>.</p>

</div>
<div *ngIf="panelId == 155">
<p>Ribosome profiling reveals ribosome stalling on tryptophan codons and ribosome queuing upon oxidative stress in fission yeast..</p>

<p>Originally published in <a href='https://academic.oup.com/nar/article/49/1/383/6031432'>Nucleic Acids Research</a>.</p>

</div>
<div *ngIf="panelId == 156">
<p>Abo1 is required for the H3K9me2 to H3K9me3 transition in heterochromatin.</p>

<p>Originally published in <a href='https://www.nature.com/articles/s41598-020-63209-y'>Scientific Reports</a>.</p>

</div>
<div *ngIf="panelId == 157">
<p>Repression of a large number of genes requires interplay between homologous recombination and HIRA.</p>

<p>Originally published in <a href='https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkab027/6123375'>Nucleic Acids Research</a>.</p>

</div>
<div *ngIf="panelId == 158">
<p>The Hydrophobic Patch Directs Cyclin B to Centrosomes to Promote Global CDK Phosphorylation at Mitosis.</p>

<p>Originally published in <a href='https://doi.org/10.1016/j.cub.2019.12.053'>Current Biology</a>.</p>

</div>
<div *ngIf="panelId == 159">
<p>Replication dynamics of recombination-dependent replication forks.</p>

<p>Originally published in <a href='https://www.nature.com/articles/s41467-021-21198-0'>Nature Communications</a>.</p>

</div>
<div *ngIf="panelId == 160">
<p>TOR targets an RNA processing network to regulate facultative heterochromatin, developmental gene expression and cell proliferation.</p>

<p>Originally published in <a href='https://www.nature.com/articles/s41556-021-00631-y'>Nature Cell Biology</a>.</p>

</div>
<div *ngIf="panelId == 161">
<p>Rbm10 facilitates heterochromatin assembly via the Clr6 HDAC complex.</p>

<p>Originally published in <a href='https://epigeneticsandchromatin.biomedcentral.com/articles/10.1186/s13072-021-00382-y'>Epigenetics &amp; Chromatin</a>.</p>

</div>
<div *ngIf="panelId == 162">
<p>Differential GAP requirement for Cdc42-GTP polarization during proliferation and sexual reproduction..</p>

<p>Originally published in <a href='https://rupress.org/jcb/article/217/12/4215/120308/Differential-GAP-requirement-for-Cdc42-GTP'>Journal of Cell Biology</a>.</p>

</div>
<div *ngIf="panelId == 163">
<p>Size-Dependent Increase in RNA Polymerase II Initiation Rates Mediates Gene Expression Scaling with Cell Size.</p>

<p>Originally published in <a href='https://www.cell.com/current-biology/fulltext/S0960-9822(20)30097-X'>Current Biology</a>.</p>

</div>
<div *ngIf="panelId == 164">
<p>Schizosaccharomyces pombe KAT5 contributes to resection and repair of a DNA double strand break.</p>

<p>Originally published in <a href='https://academic.oup.com/genetics/advance-article/doi/10.1093/genetics/iyab042/6173406'>Genetics</a>.</p>

</div>
<div *ngIf="panelId == 165">
<p>Epigenetic gene silencing by heterochromatin primes fungal resistance.</p>

<p>Originally published in <a href='https://www.nature.com/articles/s41586-020-2706-x'>Nature</a>.</p>

</div>
<div *ngIf="panelId == 166">
<p>Identification and structural analysis of the Schizosaccharomyces pombe SMN complex.</p>

<p>Originally published in <a href='https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkab158/6179931'>Nucleic Acids Research</a>.</p>

</div>
<div *ngIf="panelId == 167">
<p>The Role of Non-Catalytic Domains of Hrp3 in Nucleosome Remodeling.</p>

<p>Originally published in <a href='https://www.mdpi.com/1422-0067/22/4/1793'>International Journal of Molecular Sciences</a>.</p>

</div>
<div *ngIf="panelId == 168">
<p>Analysis of the SNARE Stx8 recycling reveals that the retromer-sorting motif has undergone evolutionary divergence.</p>

<p>Originally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1009463'>PLoS Genetics</a>.</p>

</div>
<div *ngIf="panelId == 169">
<p>TOR Complex 2- independent mutations in the regulatory PIF pocket of Gad8AKT1/SGK1 define separate branches of the stress response mechanisms in fission yeast.</p>

<p>Originally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1009196'>PLoS Genetics</a>.</p>

</div>
<div *ngIf="panelId == 170">
<p>Emr1 regulates the number of foci of the endoplasmic reticulum-mitochondria encounter structure complex.</p>

<p>Originally published in <a href='https://www.nature.com/articles/s41467-020-20866-x'>Nature Communications</a>.</p>

</div>
<div *ngIf="panelId == 171">
<p>Multiple nutritional phenotypes of fission yeast mutants defective in genes encoding essential mitochondrial proteins.</p>

<p>Originally published in <a href='https://royalsocietypublishing.org/doi/10.1098/rsob.200369'>Open Biology</a>.</p>

</div>
<div *ngIf="panelId == 172">
<p>Acute Heat Stress Leads to Reversible Aggregation of Nuclear Proteins into Nucleolar Rings in Fission Yeast.</p>

<p>Originally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(20)31366-8'>Cell Reports</a>.</p>

</div>
<div *ngIf="panelId == 173">
<p>RNA polymerase backtracking results in the accumulation of fission yeast condensin at active genes.</p>

<p>Originally published in <a href='https://www.life-science-alliance.org/content/4/6/e202101046'>Life Science Alliance</a>.</p>

</div>
<div *ngIf="panelId == 174">
<p>Meikin synergizes with shugoshin to protect cohesin Rec8 during meiosis I.</p>

<p>Originally published in <a href='http://genesdev.cshlp.org/content/35/9-10/692'>Genes &amp; Development</a>.</p>

</div>
<div *ngIf="panelId == 175">
<p>The wtf4 meiotic driver utilizes controlled protein aggregation to generate selective cell death.</p>

<p>Originally published in <a href='https://elifesciences.org/articles/55694'>eLife</a>. Image from <a href="https://elifesciences.org/articles/55694#fig6">eLife 2020;9:e55694</a>, used under <a href="https://creativecommons.org/licenses/by/4.0/">CC-BY</a> license terms.</p>

</div>
<div *ngIf="panelId == 176">
<p>Genetic interactions and functional analyses of the fission yeast gsk3 and amk2 single and double mutants defective in TORC1-dependent processes.</p>

<p>Originally published in <a href='https://www.nature.com/articles/srep44257'>Scientific Reports</a>.</p>

</div>
<div *ngIf="panelId == 177">
<p>Dri1 mediates heterochromatin assembly via RNAi and histone deacetylation.</p>

<p>Originally published in <a href='https://doi.org/10.1093/genetics/iyab032'>Genetics</a>.</p>

</div>
<div *ngIf="panelId == 178">
<p>Deletion of the non-essential Rpb9 subunit of RNA polymerase II results in pleiotropic phenotypes in Schizosaccharomyces pombe.</p>

<p>Originally published in <a href='https://www.sciencedirect.com/science/article/pii/S1570963921000601'>Biochimica et Biophysica Acta</a>.</p>

</div>
<div *ngIf="panelId == 179">
<p>Substrate specificities of α1,2- and α1,3-galactosyltransferases and characterization of Gmh1p and Otg1p in Schizosaccharomyces pombe.</p>

<p>Originally published in <a href='https://doi.org/10.1093/glycob/cwab028'>Glycobiology</a>.</p>

</div>
<div *ngIf="panelId == 180">
<p>Increased expression of Polδ does not alter the canonical replication program in vivo.</p>

<p>Originally published in <a href='https://wellcomeopenresearch.org/articles/6-44/v2'>Wellcome Open Research</a>.</p>

</div>
<div *ngIf="panelId == 181">
<p>Dual Impact of a Benzimidazole Resistant β-Tubulin on Microtubule Behavior in Fission Yeast.</p>

<p>Originally published in <a href='https://www.mdpi.com/2073-4409/10/5/1042/htm'>Cells</a>.</p>

</div>
<div *ngIf="panelId == 182">
<p>The histone H3K9M mutation synergizes with H3K14 ubiquitylation to selectively sequester histone H3K9 methyltransferase Clr4 at heterochromatin.</p>

<p>Originally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(21)00476-9'>Cell Reports</a>.</p>

</div>
<div *ngIf="panelId == 183">
<p>Magnesium depletion extends fission yeast lifespan via general amino acid control activation.</p>

<p>Originally published in <a href='https://onlinelibrary.wiley.com/doi/10.1002/mbo3.1176'>MicrobiologyOpen</a>.</p>

</div>
<div *ngIf="panelId == 184">
<p>The Putative RNA-Binding Protein Dri1 Promotes the Loading of Kinesin-14/Klp2 to the Mitotic Spindle and Is Sequestered into Heat-Induced Protein Aggregates in Fission Yeast.</p>

<p>Originally published in <a href='https://www.mdpi.com/1422-0067/22/9/4795'>International Journal of Molecular Sciences</a>.</p>

</div>
<div *ngIf="panelId == 185">
<p>Linear elements are stable structures along the chromosome axis in fission yeast meiosis.</p>

<p>Originally published in <a href='https://link.springer.com/article/10.1007%2Fs00412-021-00757-w'>Chromosoma</a>.</p>

</div>
<div *ngIf="panelId == 186">
<p>Identification of sur2 mutation affecting the lifespan of fission yeast.</p>

<p>Originally published in <a href='https://doi.org/10.1093/femsle/fnab070'>FEMS Microbiology Letters</a>.</p>

</div>
<div *ngIf="panelId == 187">
<p>A conserved Ctp1/CtIP C-terminal peptide stimulates Mre11 endonuclease activity.</p>

<p>Originally published in <a href='https://www.pnas.org/content/118/11/e2016287118'>PNAS</a>.</p>

</div>
<div *ngIf="panelId == 188">
<p>Expression of Mug14 is regulated by the transcription factor Rst2 through the cAMP-dependent protein kinase pathway in Schizosaccharomyces pombe.</p>

<p>Originally published in <a href='https://link.springer.com/article/10.1007%2Fs00294-021-01194-z'>Current Genetics</a>.</p>

</div>
<div *ngIf="panelId == 189">
<p>The intra-S phase checkpoint directly regulates replication elongation to preserve the integrity of stalled replisomes.</p>

<p>Originally published in <a href='https://www.pnas.org/content/118/24/e2019183118'>PNAS</a>.</p>

</div>
<div *ngIf="panelId == 190">
<p>TORC2 inhibition of α-arrestin Aly3 mediates cell surface persistence of S. pombe Ght5 glucose transporter in low glucose.</p>

<p>Originally published in <a href='https://journals.biologists.com/jcs/article-abstract/134/10/jcs257485/268339/TORC2-inhibition-of-arrestin-Aly3-mediates-cell?redirectedFrom=fulltext'>Journal of Cell Science</a>.</p>

</div>
<div *ngIf="panelId == 191">
<p>Single-Molecule Imaging Reveals the Mechanism Underlying Histone Loading of Schizosaccharomyces pombe AAA+ ATPase Abo1. (Image from <a href="https://www.pombase.org/reference/PMID:31848341">Cho et al. 2019</a>).</p>

<p>Originally published in <a href='http://www.molcells.org/journal/view.html?doi=10.14348/molcells.2021.2242'>Molecules and Cells</a>.</p>

</div>
<div *ngIf="panelId == 192">
<p>Spatial sequestration of misfolded proteins as an active chaperone-mediated process during heat stress.</p>

<p>Originally published in <a href='https://link.springer.com/article/10.1007%2Fs00294-020-01135-2'>Current Genetics</a>.</p>

</div>
<div *ngIf="panelId == 193">
<p>Regulation of inorganic polyphosphate is required for proper vacuolar proteolysis in fission yeast.</p>

<p>Originally published in <a href='https://www.jbc.org/article/S0021-9258(21)00691-8/fulltext'>Journal of Biological Chemistry</a>.</p>

</div>
<div *ngIf="panelId == 194">
<p>Tripartite suppression of fission yeast TORC1 signaling by the GATOR1-Sea3 complex, the TSC complex, and Gcn2 kinase.</p>

<p>Originally published in <a href='https://elifesciences.org/articles/60969'>eLife</a>. Image from <a href="https://elifesciences.org/articles/60969#fig6">eLife 2021;10:e60969</a>, used under <a href="https://creativecommons.org/licenses/by/4.0/">CC-BY</a> license terms.</p>

</div>
<div *ngIf="panelId == 195">
<p>Mapping and Analysis of Swi5 and Sfr1 Phosphorylation Sites.</p>

<p>Originally published in <a href='https://www.mdpi.com/2073-4425/12/7/1014/htm'>Genes</a>.</p>

</div>
<div *ngIf="panelId == 196">
<p>Molecular and structural mechanisms of ZZ domain-mediated cargo selection by Nbr1.</p>

<p>Originally published in <a href='https://doi.org/10.15252/embj.2020107497'>The EMBO Journal</a>.</p>

</div>
<div *ngIf="panelId == 197">
<p>Molecular organization of cytokinesis node predicts the constriction rate of the contractile ring..</p>

<p>Originally published in <a href='https://rupress.org/jcb/article-abstract/220/3/e202008032/211718/Molecular-organization-of-cytokinesis-node?redirectedFrom=fulltext'>Journal of Cell Biology</a>.</p>

</div>
<div *ngIf="panelId == 198">
<p>Phosphorylation in the intrinsically disordered region of F-BAR protein Imp2 regulates its contractile ring recruitment.</p>

<p>Originally published in <a href='https://journals.biologists.com/jcs/article-abstract/doi/10.1242/jcs.258645/270972/Phosphorylation-in-the-intrinsically-disordered?redirectedFrom=fulltext'>Journal of Cell Science</a>.</p>

</div>
<div *ngIf="panelId == 199">
<p>Identification of Nrl1 Domains Responsible for Interactions with RNA-Processing Factors and Regulation of Nrl1 Function by Phosphorylation.</p>

<p>Originally published in <a href='https://www.mdpi.com/1422-0067/22/13/7011'>International Journal of Molecular Sciences</a>.</p>

</div>
<div *ngIf="panelId == 200">
<p>The INO80 Complex Regulates Epigenetic Inheritance of Heterochromatin.</p>

<p>Originally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(20)31550-3'>Cell Reports</a>.</p>

</div>
<div *ngIf="panelId == 201">
<p>The cooperative assembly of shelterin bridge provides a kinetic gateway that controls telomere length homeostasis.</p>

<p>Originally published in <a href='https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkab550/6320402'>Nucleic Acids Research</a>.</p>

</div>
<div *ngIf="panelId == 202">
<p>Fission yeast Rad8/HLTF facilitates Rad52-dependent chromosomal rearrangements through PCNA lysine 107 ubiquitination.</p>

<p>Originally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1009671'>PLoS Genetics</a>.</p>

</div>
<div *ngIf="panelId == 203">
<p>Expression of the cancer-associated DNA polymerase ε P286R in fission yeast leads to translesion synthesis polymerase dependent hypermutation and defective DNA replication.</p>

<p>Originally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1009526'>PLoS Genetics</a>.</p>

</div>
<div *ngIf="panelId == 204">
<p>Identification of ksg1 mutation showing long-lived phenotype in fission yeast.</p>

<p>Originally published in <a href='https://onlinelibrary.wiley.com/doi/epdf/10.1111/gtc.12897'>Genes to Cells</a>.</p>

</div>
<div *ngIf="panelId == 205">
<p>Fission yeast TOR complex 1 phosphorylates Psk1 through evolutionarily conserved interaction mediated by the TOS motif.</p>

<p>Originally published in <a href='https://journals.biologists.com/jcs/article/doi/10.1242/jcs.258865/272125/Fission-yeast-TOR-complex-1-phosphorylates-Psk1'>Journal of Cell Science</a>.</p>

</div>
<div *ngIf="panelId == 206">
<p>Loss of kinesin-8 improves the robustness of the self-assembled spindle in Schizosaccharomyces pombe.</p>

<p>Originally published in <a href='https://journals.biologists.com/jcs/article/134/16/jcs253799/271911/Loss-of-kinesin-8-improves-the-robustness-of-the'>Journal of Cell Science</a>.</p>

</div>
<div *ngIf="panelId == 207">
<p>Visual detection of binary, ternary and quaternary protein interactions in fission yeast using a Pil1 co-tethering assay.</p>

<p>Originally published in <a href='https://journals.biologists.com/jcs/article-abstract/134/19/jcs258774/272452/Visual-detection-of-binary-ternary-and-quaternary'>Journal of Cell Science</a>.</p>

</div>
<div *ngIf="panelId == 208">
<p>The TOR-dependent phosphoproteome and regulation of cellular protein synthesis..</p>

<p>Originally published in <a href='https://www.embopress.org/doi/full/10.15252/embj.2021107911'>EMBO Journal</a>.</p>

</div>
<div *ngIf="panelId == 209">
<p>Co-transcriptional RNA cleavage by Drosha homolog Pac1 triggers transcription termination in fission yeast.</p>

<p>Originally published in Nucleic Acids Research <a href='https://academic.oup.com/nar/article/49/15/8610/6342457'></a>.</p>

</div>
<div *ngIf="panelId == 210">
<p>SUV39 SET domains mediate crosstalk of heterochromatic histone marks.</p>

<p>Originally published in <a href='https://elifesciences.org/articles/62682'>eLife</a>. Image from <a href="https://elifesciences.org/articles/62682#fig2">eLife 2021;10:e62682</a>, used under <a href="https://creativecommons.org/licenses/by/4.0/">CC-BY</a> license terms.</p>

</div>
<div *ngIf="panelId == 211">
<p>Quality-Control Mechanism for Telomerase RNA Folding in the Cell.</p>

<p>Originally published in <a href='https://www.cell.com/cell-reports/fulltext/S2211-1247(20)31557-6'>Cell Reports</a>.</p>

</div>
<div *ngIf="panelId == 212">
<p>Counting actin in contractile rings reveals novel contributions of cofilin and type II myosins to fission yeast cytokinesis..</p>

<p>Originally published in <a href='https://www.molbiolcell.org/doi/10.1091/mbc.E21-08-0376'>Mol Biol Cell</a>.</p>

</div>
<div *ngIf="panelId == 213">
<p>The histone chaperone FACT facilitates heterochromatin spreading by regulating histone turnover and H3K9 methylation states.</p>

<p>Originally published in <a href='https://www.sciencedirect.com/science/article/pii/S2211124721014170'>Cell Rep</a>.</p>

</div>
<div *ngIf="panelId == 214">
<p>Transcription-wide mapping of dihydrouridine reveals that mRNA dihydrouridylation is required for meiotic chromosome segregation.</p>

<p>Originally published in <a href='https://www.sciencedirect.com/science/article/pii/S1097276521009539?via%3Dihub'>Mol Cell.</a>.</p>

</div>
<div *ngIf="panelId == 215">
<p>Activation of meiotic recombination by nuclear import of the DNA break hotspot-determining complex.</p>

<p>Originally published in <a href='https://journals.biologists.com/jcs/article/134/4/jcs253518/237380/Activation-of-meiotic-recombination-by-nuclear'>J Cell Sci</a>.</p>

</div>
<div *ngIf="panelId == 216">
<p>Genetic screen for suppression of transcriptional interference identifies a gain-of-function mutation in Pol2 termination factor Seb1.</p>

<p>Originally published in <a href='https://www.pnas.org/content/118/33/e2108105118'>PNAS</a>.</p>

</div>
<div *ngIf="panelId == 217">
<p>Structure of Fission Yeast Transcription Factor Pho7 Bound to pho1 Promoter DNA and Effect of Pho7 Mutations on DNA Binding and Phosphate Homeostasis.</p>

<p>Originally published in <a href='https://journals.asm.org/doi/10.1128/MCB.00132-19'>Mol. Cell. Biol.</a>.</p>

</div>
<div *ngIf="panelId == 218">
<p>The fission yeast FLCN/FNIP complex augments TORC1 repression or activation in response to amino acid (AA) availability.</p>

<p>Originally published in <a href='https://linkinghub.elsevier.com/retrieve/pii/S2589004221013079'>iScience</a>.</p>

</div>
<div *ngIf="panelId == 219">
<p>The Ceramide Synthase Subunit Lac1 Regulates Cell Growth and Size in Fission Yeast.</p>

<p>Originally published in <a href='https://www.mdpi.com/1422-0067/23/1/303'>Int J Mol Sci</a>.</p>

</div>
<div *ngIf="panelId == 220">
<p>R-loops and regulatory changes in chronologically ageing fission yeast cells drive non-random patterns of genome rearrangements.</p>

<p>Originally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1009784'>PLoS Genet</a>.</p>

</div>
<div *ngIf="panelId == 221">
<p>Size-Dependent Expression of the Mitotic Activator Cdc25 Suggests a Mechanism of Size Control in Fission Yeast.</p>

<p>Originally published in <a href='https://linkinghub.elsevier.com/retrieve/pii/S0960982217304256'>Curr. Biol.</a>.</p>

</div>
<div *ngIf="panelId == 222">
<p>A TOR (target of rapamycin) and nutritional phosphoproteome of fission yeast reveals novel targets in networks conserved in humans.</p>

<p>Originally published in <a href='https://royalsocietypublishing.org/doi/10.1098/rsob.200405'>Open Biol</a>.</p>

</div>
<div *ngIf="panelId == 223">
<p>TOR complex 2 contributes to regulation of gene expression via inhibiting Gcn5 recruitment to subtelomeric and DNA replication stress genes.</p>

<p>Originally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1010061'>PLoS Genet</a>.</p>

</div>
<div *ngIf="panelId == 224">
<p>Dicer promotes genome stability via the bromodomain transcriptional co-activator BRD4.</p>

<p>Originally published in <a href='https://www.nature.com/articles/s41467-022-28554-8'>Nat Commun</a>.</p>

</div>
<div *ngIf="panelId == 225">
<p>Involvement of Smi1 in cell wall integrity and glucan synthase Bgs4 localization during fission yeast cytokinesis..</p>

<p>Originally published in <a href='https://www.molbiolcell.org/doi/10.1091/mbc.E21-04-0214'>Mol Biol Cell </a>.</p>

</div>
<div *ngIf="panelId == 226">
<p>Canavanine resistance mutation can1-1 in Schizosaccharomyces pombe is a missense mutation in the ubiquitin ligase adaptor gene any1.</p>

<p>Originally published in <a href='https://www.micropublication.org/journals/biology/micropub-biology-000538'>MicroPubl Biol </a>.</p>

</div>
<div *ngIf="panelId == 227">
<p>Cdc48 influence on separase levels is independent of mitosis and suggests translational sensitivity of separase.</p>

<p>Originally published in <a href='https://linkinghub.elsevier.com/retrieve/pii/S2211124722002984'>Cell Rep.</a>.</p>

</div>
<div *ngIf="panelId == 228">
<p>Rec8 Cohesin-mediated Axis-loop chromatin architecture is required for meiotic recombination.</p>

<p>Originally published in <a href='https://academic.oup.com/nar/article/50/7/3799/6554166'>Nucleic Acids Research</a>.</p>

</div>
<div *ngIf="panelId == 229">
<p>Microtubule rescue at midzone edges promotes overlap stability and prevents spindle collapse during anaphase B.</p>

<p>Originally published in <a href='https://elifesciences.org/articles/72630'>Elife</a>.</p>

</div>
<div *ngIf="panelId == 230">
<p>The actin assembly requirements of the formin Fus1 to build the fusion focus.</p>

<p>Originally published in <a href='https://doi.org/10.1242/jcs.260289'>Journal of Cell Science</a>.</p>

</div>
<div *ngIf="panelId == 231">
<p>The methyl phosphate capping enzyme Bmc1/Bin3 is a stable component of the fission yeast telomerase holoenzyme.</p>

<p>Originally published in <a href='https://www.nature.com/articles/s41467-022-28985-3'>Nature Communications</a>.</p>

</div>
<div *ngIf="panelId == 232">
<p>Mitotic spindle formation in the absence of Polo kinase.</p>

<p>Originally published in <a href='https://www.pnas.org/doi/full/10.1073/pnas.2114429119'>PNAS</a>.</p>

</div>
<div *ngIf="panelId == 233">
<p>Fission stories: using PomBase to understand Schizosaccharomyces pombe biology.</p>

<p>Originally published in <a href='https://academic.oup.com/genetics/advance-article/doi/10.1093/genetics/iyab222/6481557'>Genetics</a>.</p>

</div>
<div *ngIf="panelId == 234">
<p>Isolated THATCH domain of End4 is unable to bind F-actin independently in the fission yeast Schizosaccharomyces pombe.</p>

<p>Originally published in <a href='https://www.micropublication.org/journals/biology/micropub-biology-000508'>MicroPubl</a>.</p>

</div>
<div *ngIf="panelId == 235">
<p>Characterization of canavanine-resistance of cat1 and vhc1 deletions and a dominant any1 mutation in fission yeast.</p>

<p>Originally published in <a href='https://www.pombase.org/reference/PMID:35639710'>PLoS One</a>.</p>

</div>
<div *ngIf="panelId == 236">
<p>Arf6 anchors Cdr2 nodes at the cell cortex to control cell size at division..</p>

<p>Originally published in <a href='https://rupress.org/jcb/article/221/2/e202109152/212942/Arf6-anchors-Cdr2-nodes-at-the-cell-cortex-to'>JCB</a>.</p>

</div>
<div *ngIf="panelId == 237">
<p>Coordinated cortical ER remodeling facilitates actomyosin ring assembly.</p>

<p>Originally published in <a href='https://www.cell.com/current-biology/fulltext/S0960-9822(22)00722-9?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS0960982222007229%3Fshowall%3Dtrue'>Current Biology</a>.</p>

</div>
<div *ngIf="panelId == 238">
<p>Detection of surface forces by the cell-wall mechanosensor Wsc1 in yeast.</p>

<p>Originally published in <a href='https://www.cell.com/developmental-cell/fulltext/S1534-5807(21)00768-1?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS1534580721007681%3Fshowall%3Dtrue'>Dev Cell</a>.</p>

</div>
<div *ngIf="panelId == 239">
<p>Ccp1-Ndc80 switch at the N terminus of CENP-T regulates kinetochore assembly.</p>

<p>Originally published in <a href='https://www.pnas.org/doi/full/10.1073/pnas.2104459118'>Proc Natl Acad Sci</a>.</p>

</div>
<div *ngIf="panelId == 240">
<p>Queuosine salvage in fission yeast by Qng1-mediated hydrolysis to queuine.</p>

<p>Originally published in <a href='https://www.sciencedirect.com/science/article/abs/pii/S0006291X22010919?via%3Dihub'>Biochem Biophys Res Commun</a>.</p>

</div>
<div *ngIf="panelId == 241">
<p>Queuine links translational control in eukaryotes to a micronutrient from bacteria.</p>

<p>Originally published in <a href='https://academic.oup.com/nar/article/47/7/3711/5305261'>Nucleic Acids Res.</a>.</p>

</div>
<div *ngIf="panelId == 242">
<p>Brc1 Promotes the Focal Accumulation and SUMO Ligase Activity of Smc5-Smc6 during Replication Stress.</p>

<p>Originally published in <a href='https://journals.asm.org/doi/10.1128/MCB.00271-18'>Mol. Cell. Biol.</a>.</p>

</div>
<div *ngIf="panelId == 243">
<p>The Mis6 inner kinetochore subcomplex maintains CENP-A nucleosomes against centromeric non-coding transcription during mitosis.</p>

<p>Originally published in <a href='https://www.nature.com/articles/s42003-022-03786-y'>Commun Biol</a>.</p>

</div>
<div *ngIf="panelId == 244">
<p>Role of Nse1 Subunit of SMC5/6 Complex as a Ubiquitin Ligase.</p>

<p>Originally published in <a href='https://www.mdpi.com/2073-4409/11/1/165'>Cell</a>.</p>

</div>
<div *ngIf="panelId == 245">
<p>Inactivation of fission yeast Erh1 de-represses pho1 expression: evidence that Erh1 is a negative regulator of prt lncRNA termination</p>

<p>Originally published in <a href='https://rnajournal.cshlp.org/content/26/10/1334'>RNA</a>.</p>

</div>
<div *ngIf="panelId == 246">
<p>Fission yeast Duf89 and Duf8901 are cobalt/nickel-dependent phosphatase-pyrophosphatases that act via a covalent aspartyl-phosphate intermediate.</p>

<p>Originally published in <a href='https://www.jbc.org/article/S0021-9258(22)00291-5/fulltext'>J Biol Chem</a>.</p>

</div>
<div *ngIf="panelId == 247">
<p>Inositol pyrophosphates impact phosphate homeostasis via modulation of RNA 3’ processing and transcription termination.</p>

<p>Originally published in <a href='https://academic.oup.com/nar/article/47/16/8452/5528736'>Nucleic Acids Research</a>.</p>

</div>
<div *ngIf="panelId == 248">
<p>Genetic screen for suppression of transcriptional interference reveals fission yeast 14-3-3 protein Rad24 as an antagonist of precocious Pol2 transcription termination.</p>

<p>Originally published in <a href='https://academic.oup.com/nar/article/50/2/803/6489952'>Nucleic Acids Research</a>.</p>

</div>
<div *ngIf="panelId == 249">
<p>Transcriptional profiling of fission yeast RNA polymerase II CTD mutants.</p>

<p>Originally published in <a href='https://rnajournal.cshlp.org/content/27/5/560'>RNA</a>.</p>

</div>
<div *ngIf="panelId == 250">
<p>Genetic interactions and transcriptomics implicate fission yeast CTD prolyl isomerase Pin1 as an agent of RNA 3’ processing and transcription termination that functions via its effects on CTD phosphatase Ssu72.</p>

<p>Originally published in <a href='https://academic.oup.com/nar/article/48/9/4811/5819598'>Nucleic Acids Res.</a>.</p>

</div>
<div *ngIf="panelId == 251">
<p>Cleavage-Polyadenylation Factor Cft1 and SPX Domain Proteins Are Agents of Inositol Pyrophosphate Toxicosis in Fission Yeast..</p>

<p>Originally published in <a href='https://journals.asm.org/doi/10.1128/mbio.03476-21'>mBio</a>.</p>

</div>
<div *ngIf="panelId == 252">
<p>Interactome of Spliceosome-Associated G-Patch Protein Gpl1 in the Fission Yeast Schizosaccharomyces pombe.</p>

<p>Originally published in <a href='https://www.mdpi.com/1422-0067/23/21/12800'>Int J Mol Sci</a>.</p>

</div>
<div *ngIf="panelId == 253">
<p>Recovery from spindle checkpoint-mediated arrest requires a novel Dnt1-dependent APC/C activation mechanism.</p>

<p>Originally published in <a href='https://www.pombase.org/reference/PMID:36108046'>PLoS Genet</a>.</p>

</div>
<div *ngIf="panelId == 254">
<p>Ubiquitination of CLIP-170 family protein restrains polarized growth upon DNA replication stress.</p>

<p>Originally published in <a href='https://www.nature.com/articles/s41467-022-33311-y'>Nat Commun</a>.</p>

</div>
<div *ngIf="panelId == 255">
<p>Membrane stretching activates calcium-permeability of a putative channel Pkd2 during fission yeast cytokinesis..</p>

<p>Originally published in <a href='https://www.molbiolcell.org/doi/10.1091/mbc.E22-07-0248'>Molecular Biology of the Cell</a>.</p>

</div>
<div *ngIf="panelId == 256">
<p>Diacylglycerol at the inner nuclear membrane fuels nuclear envelope expansion in closed mitosis..</p>

<p>Originally published in <a href='https://journals.biologists.com/jcs/article/136/3/jcs260568/286881/Diacylglycerol-at-the-inner-nuclear-membrane-fuels'>J Cell Sci</a>.</p>

</div>
<div *ngIf="panelId == 257">
<p>An ecl family gene ecl3+ is induced by phosphate starvation and contributes to sexual differentiation in fission yeast..</p>

<p>Originally published in <a href='https://journals.biologists.com/jcs/article/doi/10.1242/jcs.260759/287015/An-ecl-family-gene-ecl3-is-induced-by-phosphate'>J Cell Sci</a>.</p>

</div>
<div *ngIf="panelId == 258">
<p>Analysis of the potential role of fission yeast PP2A in spindle assembly checkpoint inactivation.</p>



<p>Originally published in <a href='https://doi.org/10.1096/fj.202101884R'>FASEB J</a>.</p>

</div>
<div *ngIf="panelId == 259">
<p>Mechanistic insights into the anchorage of the contractile ring by anillin and Mid1.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.devcel.2015.03.003'>Dev Cell</a>.</p>

</div>
<div *ngIf="panelId == 260">
<p>Cryo-EM structure and function of S. pombe complex IV with bound respiratory supercomplex factor.</p>



<p>Originally published in <a href='https://doi.org/10.1038/s42004-023-00827-3'>Commun Chem</a>.</p>

</div>
<div *ngIf="panelId == 261">
<p>Tandemly repeated genes promote RNAi-mediated heterochromatin formation via an antisilencing factor, Epe1, in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1101/gad.350129.122'>Genes Dev</a>.</p>

</div>
<div *ngIf="panelId == 262">
<p>The fission yeast kinetochore complex Mhf1-Mhf2 regulates the spindle assembly checkpoint and faithful chromosome segregation.</p>



<p>Originally published in <a href='https://doi.org/10.1242/jcs.260124'>J Cell Sci</a>.</p>

</div>
<div *ngIf="panelId == 263">
<p>Critical role of Wat1/Pop3 in regulating the TORC1 signalling pathway in fission yeast S. pombe.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.fgb.2022.103764'>Fungal Genet Biol</a>.</p>

</div>
<div *ngIf="panelId == 264">
<p>Inner nuclear membrane proteins Lem2 and Bqt4 interact with different lipid synthesis enzymes in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1093/jb/mvad017'>J Biochem</a>.</p>

</div>
<div *ngIf="panelId == 265">
<p>Hva22, a REEP family protein in fission yeast, promotes reticulophagy in collaboration with a receptor protein.</p>



<p>Originally published in <a href='https://doi.org/10.1080/15548627.2023.2214029'>Autophagy</a>.</p>

</div>
<div *ngIf="panelId == 266">
<p>The mitochondrial intermembrane space protein mitofissin drives mitochondrial fission required for mitophagy.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.molcel.2023.04.022'>Mol Cell</a>.</p>

</div>
<div *ngIf="panelId == 267">
<p>Force by minus-end motors Dhc1 and Klp2 collapses the S. pombe spindle after laser ablation.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.bpj.2021.12.019'>Biophys J</a>.</p>

</div>
<div *ngIf="panelId == 268">
<p>Identification of novel coenzyme Q10 biosynthetic proteins Coq11 and Coq12 in Schizosaccharomyces pombe.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.jbc.2023.104797'>J Biol Chem</a>.</p>

</div>
<div *ngIf="panelId == 269">
<p>Fission yeast Srr1 and Skb1 promote isochromosome formation at the centromere.</p>



<p>Originally published in <a href='https://doi.org/10.1038/s42003-023-04925-9'>Commun Biol</a>.</p>

</div>
<div *ngIf="panelId == 270">
<p>Chiasmata and the kinetochore component Dam1 are crucial for elimination of erroneous chromosome attachments and centromere oscillation at meiosis I.</p>



<p>Originally published in <a href='https://doi.org/10.1098/rsob.200308'>Open Biol</a>.</p>

</div>
<div *ngIf="panelId == 271">
<p>Direct evaluation of cohesin-mediated sister kinetochore associations at meiosis I in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1242/jcs.259102'>J Cell Sci</a>.</p>

</div>
<div *ngIf="panelId == 272">
<p>The fission yeast cytokinetic ring component Fic1 promotes septum formation.</p>



<p>Originally published in <a href='https://doi.org/10.1242/bio.059957'>Biol Open</a>.</p>

</div>
<div *ngIf="panelId == 273">
<p>Optimization of energy production and central carbon metabolism in a non-respiring eukaryote.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.cub.2023.04.046'>Curr Biol</a>.</p>

</div>
<div *ngIf="panelId == 274">
<p>Unraveling the kinetochore nanostructure in Schizosaccharomyces pombe using multi-color SMLM imaging.</p>



<p>Originally published in <a href='https://doi.org/10.1083/jcb.202209096'>J Cell Biol</a>.</p>

</div>
<div *ngIf="panelId == 275">
<p>Mitotic checkpoint gene expression is tuned by codon usage bias.</p>



<p>Originally published in <a href='https://doi.org/10.15252/embj.2021107896'>EMBO J</a>.</p>

</div>
<div *ngIf="panelId == 276">
<p>CDK actively contributes to establishment of the stationary phase state in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1242/jcs.260727'>J Cell Sci</a>.</p>

</div>
<div *ngIf="panelId == 277">
<p>The fission yeast methyl phosphate capping enzyme Bmc1 guides 2’-O-methylation of the U6 snRNA.</p>



<p>Originally published in <a href='https://doi.org/10.1093/nar/gkad563'>Nucleic Acids Res</a>.</p>

</div>
<div *ngIf="panelId == 278">
<p>Membrane binding of endocytic myosin-1s is inhibited by a class of ankyrin repeat proteins.</p>



<p>Originally published in <a href='https://doi.org/10.1091/mbc.E23-06-0233'>Mol Biol Cell</a>.</p>

</div>
<div *ngIf="panelId == 279">
<p>Completion of mitochondrial division requires the intermembrane space protein Mdi1/Atg44.</p>



<p>Originally published in <a href='https://doi.org/10.1083/jcb.202303147'>J Cell Biol</a>.</p>

</div>
<div *ngIf="panelId == 280">
<p>Cellular responses to long-term phosphate starvation of fission yeast: Maf1 determines fate choice between quiescence and death associated with aberrant tRNA biogenesis.</p>



<p>Originally published in <a href='https://doi.org/10.1093/nar/gkad063'>Nucleic Acids Res</a>.</p>

</div>
<div *ngIf="panelId == 281">
<p>Splicing of branchpoint-distant exons is promoted by Cactin, Tls1 and the ubiquitin-fold-activated Sde2.</p>



<p>Originally published in <a href='https://doi.org/10.1093/nar/gkac769'>Nucleic Acids Res</a>.</p>

</div>
<div *ngIf="panelId == 282">
<p>The AAA-ATPase Yta4/ATAD1 interacts with the mitochondrial divisome to inhibit mitochondrial fission.</p>



<p>Originally published in <a href='https://doi.org/10.1371/journal.pbio.3002247'>PLoS Biol</a>.</p>

</div>
<div *ngIf="panelId == 283">
<p>Chaperone-mediated ordered assembly of the SAGA and NuA4 transcription co-activator complexes in yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1038/s41467-019-13243-w'>Nat Commun</a>.</p>

</div>
<div *ngIf="panelId == 284">
<p>The Hsp90 cochaperone TTT promotes cotranslational maturation of PIKKs prior to complex assembly.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.celrep.2021.109867'>Cell Rep</a>.</p>

</div>
<div *ngIf="panelId == 285">
<p>Cooperative DNA-binding activities of Chp2 are critical for its function in heterochromatin assembly.</p>



<p>Originally published in <a href='https://doi.org/10.1093/jb/mvad050'>J Biochem</a>.</p>

</div>
<div *ngIf="panelId == 286">
<p>An ESCRT grommet cooperates with a diffusion barrier to maintain nuclear integrity.</p>



<p>Originally published in <a href='https://doi.org/10.1038/s41556-023-01235-4'>Nat Cell Biol</a>.</p>

</div>
<div *ngIf="panelId == 287">
<p>Elevated levels of sphingolipid MIPC in the plasma membrane disrupt the coordination of cell growth with cell wall formation in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1371/journal.pgen.1010987'>PLoS Genet</a>.</p>

</div>
<div *ngIf="panelId == 288">
<p>Characterization of Pik1 function in fission yeast reveals its conserved role in lipid synthesis and not cytokinesis.</p>



<p>Originally published in <a href='https://doi.org/10.1242/jcs.261415'>J Cell Sci</a>.</p>

</div>
<div *ngIf="panelId == 289">
<p>In fission yeast, 65 non-essential mitochondrial proteins related to respiration and stress become essential in low-glucose conditions.</p>



<p>Originally published in <a href='https://doi.org/10.1098/rsos.230404'>R Soc Open Sci</a>.</p>

</div>
<div *ngIf="panelId == 290">
<p>A ubiquitin-proteasome pathway degrades the inner nuclear membrane protein Bqt4 to maintain nuclear membrane homeostasis.</p>



<p>Originally published in <a href='https://doi.org/10.1242/jcs.260930'>J Cell Sci</a>.</p>

</div>
<div *ngIf="panelId == 291">
<p>SUMOylation regulates Lem2 function in centromere clustering and silencing.</p>



<p>Originally published in <a href='https://doi.org/10.1242/jcs.260868'>J Cell Sci</a>.</p>

</div>
<div *ngIf="panelId == 292">
<p>The roles of fission yeast exonuclease 5 in nuclear and mitochondrial genome stability.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.dnarep.2019.102720'>DNA Repair (Amst)</a>.</p>

</div>
<div *ngIf="panelId == 293">
<p>Genetic suppressor screen identifies Tgp1 (glycerophosphocholine transporter), Kcs1 (IP6 kinase), and Plc1 (phospholipase C) as determinants of inositol pyrophosphate toxicosis in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1128/mbio.03062-23'>mBio</a>.</p>

</div>
<div *ngIf="panelId == 294">
<p>Regulation of ectopic heterochromatin-mediated epigenetic diversification by the JmjC family protein Epe1.</p>



<p>Originally published in <a href='https://doi.org/10.1371/journal.pgen.1008129'>PLoS Genet</a>.</p>

</div>
<div *ngIf="panelId == 295">
<p>Regulation of cell size and Wee1 kinase by elevated levels of the cell cycle regulatory protein kinase Cdr2.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.jbc.2022.102831'>J Biol Chem</a>.</p>

</div>
<div *ngIf="panelId == 296">
<p>A dominant negative 14-3-3 mutant in Schizosaccharomyces pombe distinguishes the binding proteins involved in sexual differentiation and check point.</p>



<p>Originally published in <a href='https://doi.org/10.1371/journal.pone.0291524'>PLoS One</a>.</p>

</div>
<div *ngIf="panelId == 297">
<p>Fission yeast Wee1 is required for stable kinetochore-microtubule attachment.</p>



<p>Originally published in <a href='https://doi.org/10.1098/rsob.230379'>Open Biol</a>.</p>

</div>
<div *ngIf="panelId == 298">
<p>Establishment of dsDNA-dsDNA interactions by the condensin complex.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.molcel.2023.09.019'>Mol Cell</a>.</p>

</div>
<div *ngIf="panelId == 299">
<p>TOR inactivation triggers heterochromatin formation in rDNA during glucose starvation.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.celrep.2023.113320'>Cell Rep</a>.</p>

</div>
<div *ngIf="panelId == 300">
<p>A peroxiredoxin-P38 MAPK scaffold increases MAPK activity by MAP3K-independent mechanisms.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.molcel.2023.07.018'>Mol Cell</a>.</p>

</div>
<div *ngIf="panelId == 301">
<p>Regulation of mRNA Levels by Decay-Promoting Introns that Recruit the Exosome Specificity Factor Mmi1.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.celrep.2015.11.026'>Cell Rep</a>.</p>

</div>
<div *ngIf="panelId == 302">
<p>How an mRNA capping enzyme reads distinct RNA polymerase II and Spt5 CTD phosphorylation codes.</p>



<p>Originally published in <a href='https://doi.org/10.1101/gad.242768.114'>Genes Dev</a>.</p>

</div>
<div *ngIf="panelId == 303">
<p>RNA polymerase II CTD phospho-sites Ser5 and Ser7 govern phosphate homeostasis in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1261/rna.052555.115'>RNA</a>.</p>

</div>
<div *ngIf="panelId == 304">
<p>Initiator tRNA lacking 1-methyladenosine is targeted by the rapid tRNA decay pathway in evolutionarily distant yeast species.</p>



<p>Originally published in <a href='https://doi.org/10.1371/journal.pgen.1010215'>PLoS Genet</a>.</p>

</div>
<div *ngIf="panelId == 305">
<p>Specialized replication of heterochromatin domains ensures self-templated chromatin assembly and epigenetic inheritance.</p>



<p>Originally published in <a href='https://doi.org/10.1073/pnas.2315596121'>Proc Natl Acad Sci U S A</a>.</p>

</div>
<div *ngIf="panelId == 306">
<p>Resistance to Chemotherapeutic 5-Fluorouracil Conferred by Modulation of Heterochromatic Integrity through Ino80 Function in Fission Yeast.</p>



<p>Originally published in <a href='https://doi.org/10.3390/ijms241310687'>Int J Mol Sci</a>.</p>

</div>
<div *ngIf="panelId == 307">
<p>Schizosaccharomyces pombe Rtf2 is important for replication fork barrier activity of RTS1 via splicing of Rtf1.</p>



<p>Originally published in <a href='https://doi.org/10.7554/eLife.78554'>Elife</a>.</p>

</div>
<div *ngIf="panelId == 308">
<p>Coordination of histone chaperones for parental histone segregation and epigenetic inheritance.</p>



<p>Originally published in <a href='https://doi.org/10.1101/gad.351278.123'>Genes Dev</a>.</p>

</div>
<div *ngIf="panelId == 309">
<p>Rapamycin-sensitive mechanisms confine the growth of fission yeast below the temperatures detrimental to cell physiology.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.isci.2023.108777'>iScience</a>.</p>

</div>
<div *ngIf="panelId == 310">
<p>Heat stress-induced activation of MAPK pathway attenuates Atf1-dependent epigenetic inheritance of heterochromatin in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.7554/eLife.90525'>Elife</a>.</p>

</div>
<div *ngIf="panelId == 311">
<p>A conserved membrane curvature-generating protein is crucial for autophagosome formation in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1038/s41467-023-40530-4'>Nat Commun</a>.</p>

</div>
<div *ngIf="panelId == 312">
<p>Generation and characterization of temperature-sensitive alleles encoding GPI anchored proteins Psu1 and Dfg502 in Schizosaccharomyces pombe.</p>



<p>Originally published in <a href='https://doi.org/10.17912/micropub.biology.001179'>MicroPubl Biol</a>.</p>

</div>
<div *ngIf="panelId == 313">
<p>The Cross-Regulation Between Set1, Clr4, and Lsd1/2 in Schizosaccharomyces pombe.</p>



<p>Originally published in <a href='https://doi.org/10.1371/journal.pgen.1011107'>PLoS Genet</a>.</p>

</div>
<div *ngIf="panelId == 314">
<p>Phosphate uptake restriction, phosphate export, and polyphosphate synthesis contribute synergistically to cellular proliferation and survival.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.jbc.2023.105454'>J Biol Chem</a>.</p>

</div>
<div *ngIf="panelId == 315">
<p>Fission yeast Swi2 designates cell-type specific donor and stimulates Rad51-driven strand exchange for mating-type switching.</p>



<p>Originally published in <a href='https://doi.org/10.1093/nar/gkad204'>Nucleic Acids Res</a>.</p>

</div>
<div *ngIf="panelId == 316">
<p>Iron homeostasis proteins Grx4 and Fra2 control activity of the Schizosaccharomyces pombe iron repressor Fep1 by facilitating [2Fe-2S] cluster removal.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.jbc.2023.105419'>J Biol Chem</a>.</p>

</div>
<div *ngIf="panelId == 317">
<p>A dynamin superfamily-like pseudoenzyme coordinates with MICOS to promote cristae architecture.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.cub.2024.04.028'>Curr Biol</a>.</p>

</div>
<div *ngIf="panelId == 318">
<p>The fission yeast NDR kinase Orb6 and its signalling pathway MOR regulate cytoplasmic microtubule organization during the cell cycle.</p>



<p>Originally published in <a href='https://doi.org/10.1098/rsob.230440'>Open Biol</a>.</p>

</div>
<div *ngIf="panelId == 319">
<p>Force redistribution in clathrin-mediated endocytosis revealed by coiled-coil force sensors.</p>



<p>Originally published in <a href='https://doi.org/10.1126/sciadv.adi1535'>Sci Adv</a>.</p>

</div>
<div *ngIf="panelId == 320">
<p>Characterization of temperature-sensitive Schizosaccharomyces pombe mutants in the septation initiation network Spg1 GTPase.</p>



<p>Originally published in <a href='https://doi.org/10.17912/micropub.biology.001193'>MicroPubl Biol</a>.</p>

</div>
<div *ngIf="panelId == 321">
<p>Transient PP2A SIP complex localization to mitotic SPBs for SIN inhibition is mediated solely by the Csc1 FHA domain.</p>



<p>Originally published in <a href='https://doi.org/10.1091/mbc.E24-04-0196'>Mol Biol Cell</a>.</p>

</div>
<div *ngIf="panelId == 322">
<p>Suppression of inositol pyrophosphate toxicosis and hyper-repression of the fission yeast PHO regulon by loss-of-function mutations in chromatin remodelers Snf22 and Sol1.</p>



<p>Originally published in <a href='https://doi.org/10.1128/mbio.01252-24'>mBio</a>.</p>

</div>
<div *ngIf="panelId == 323">
<p>The core spindle pole body scaffold Ppc89 links the pericentrin orthologue Pcp1 to the fission yeast spindle pole body via an evolutionarily conserved interface.</p>



<p>Originally published in <a href='https://doi.org/10.1091/mbc.E24-05-0220'>Mol Biol Cell</a>.</p>

</div>
<div *ngIf="panelId == 324">
<p>Characterization of temperature-sensitive alleles of Schizosaccharomyces pombe septation initiation network components.</p>



<p>Originally published in <a href='https://doi.org/10.17912/micropub.biology.001249'>MicroPubl Biol</a>.</p>

</div>
<div *ngIf="panelId == 325">
<p>Disordered regions and folded modules in CAF-1 promote histone deposition in Schizosaccharomyces pombe.</p>



<p>Originally published in <a href='https://doi.org/10.7554/eLife.91461'>Elife</a>.</p>

</div>
<div *ngIf="panelId == 326">
<p>Critical importance of DNA binding for CSL protein functions in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1242/jcs.261568'>J Cell Sci</a>.</p>

</div>
<div *ngIf="panelId == 327">
<p>SUMO protease and proteasome recruitment at the nuclear periphery differently affect replication dynamics at arrested forks.</p>



<p>Originally published in <a href='https://doi.org/10.1093/nar/gkae526'>Nucleic Acids Res</a>.</p>

</div>
<div *ngIf="panelId == 328">
<p>Activities and genetic interactions of fission yeast Aps1, a Nudix-type inositol pyrophosphatase and inorganic polyphosphatase.</p>



<p>Originally published in <a href='https://doi.org/10.1128/mbio.01084-24'>mBio</a>.</p>

</div>
<div *ngIf="panelId == 329">
<p>Mutational analyses of the interacting domains of Schizosaccharomyces pombe Byr2 with 14-3-3s.</p>



<p>Originally published in <a href='https://doi.org/10.1007/s00294-024-01293-7'>Curr Genet</a>.</p>

</div>
<div *ngIf="panelId == 330">
<p>Fission yeast Duc1 links to ER-PM contact sites and influences PM lipid composition and cytokinetic ring anchoring.</p>



<p>Originally published in <a href='https://doi.org/10.1242/jcs.262347'>J Cell Sci</a>.</p>

</div>
<div *ngIf="panelId == 331">
<p>Cdc42 prevents precocious Rho1 activation during cytokinesis in a Pak1-dependent manner.</p>



<p>Originally published in <a href='https://doi.org/10.1242/jcs.261160'>J Cell Sci</a>.</p>

</div>
<div *ngIf="panelId == 332">
<p>Characterization of Shy1, the Schizosaccharomyces pombe homolog of human SURF1.</p>



<p>Originally published in <a href='https://doi.org/10.1038/s41598-024-72681-9'>Sci Rep</a>.</p>

</div>
<div *ngIf="panelId == 333">
<p>A novel transcription factor Sdr1 involving sulfur depletion response in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1111/gtc.13136'>Genes Cells</a>.</p>

</div>
<div *ngIf="panelId == 334">
<p>CDK phosphorylation of Sfr1 downregulates Rad51 function in late-meiotic homolog invasions.</p>



<p>Originally published in <a href='https://doi.org/10.1038/s44318-024-00205-2'>EMBO J</a>.</p>

</div>
<div *ngIf="panelId == 335">
<p>Cytoplasmic fluidization contributes to breaking spore dormancy in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1073/pnas.2405553121'>Proc Natl Acad Sci U S A</a>.</p>

</div>
<div *ngIf="panelId == 336">
<p>Sls1 and Mtf2 mediate the assembly of the Mrh5C complex required for activation of cox1 mRNA translation.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.jbc.2024.107176'>J Biol Chem</a>.</p>

</div>
<div *ngIf="panelId == 337">
<p>Ribosomes hibernate on mitochondria during cellular stress.</p>



<p>Originally published in <a href='https://doi.org/10.1038/s41467-024-52911-4'>Nat Commun</a>.</p>

</div>
<div *ngIf="panelId == 338">
<p>Reprogramming Cdr2-Dependent Geometry-Based Cell Size Control in Fission Yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.cub.2018.12.017'>Curr Biol</a>.</p>

</div>
<div *ngIf="panelId == 339">
<p>Cdc42 mobility and membrane flows regulate fission yeast cell shape and survival.</p>



<p>Originally published in <a href='https://doi.org/10.1038/s41467-024-52655-1'>Nat Commun</a>.</p>

</div>
<div *ngIf="panelId == 340">
<p>The Meiosis-Specific Crs1 Cyclin Is Required for Efficient S-Phase Progression and Stable Nuclear Architecture.</p>



<p>Originally published in <a href='https://doi.org/10.3390/ijms22115483'>Int J Mol Sci</a>.</p>

</div>
<div *ngIf="panelId == 341">
<p>New mutations in the core Schizosaccharomyces pombe spindle pole body scaffold Ppc89 reveal separable functions in regulating cell division.</p>



<p>Originally published in <a href='https://doi.org/10.1093/g3journal/jkae249'>G3 (Bethesda)</a>.</p>

</div>
<div *ngIf="panelId == 342">
<p>Schizosaccharomyces pombe Ppr10 and Mpa1 together mediate mitochondrial translational initiation.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.jbc.2021.100869'>J Biol Chem</a>.</p>

</div>
<div *ngIf="panelId == 343">
<p>VAP-mediated membrane-tethering mechanisms implicate ER-PM contact function in pH homeostasis.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.celrep.2024.114592'>Cell Rep</a>.</p>

</div>
<div *ngIf="panelId == 344">
<p>Mapping the dynamics of epigenetic adaptation in S. pombe during heterochromatin misregulation.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.devcel.2024.07.006'>Dev Cell</a>.</p>

</div>
<div *ngIf="panelId == 345">
<p>Ageing-associated long non-coding RNA extends lifespan and reduces translation in non-dividing cells.</p>



<p>Originally published in <a href='https://doi.org/10.1038/s44319-024-00265-9'>EMBO Rep</a>.</p>

</div>
<div *ngIf="panelId == 346">
<p>E3 ubiquitin ligase Hul6 modulates iron-dependent metabolism by regulating Php4 stability.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.jbc.2024.105670'>J Biol Chem</a>.</p>

</div>
<div *ngIf="panelId == 347">
<p>Quantitative proteomics and phosphoproteomics profiling of meiotic divisions in the fission yeast Schizosaccharomyces pombe.</p>



<p>Originally published in <a href='https://doi.org/10.1038/s41598-024-74523-0'>Sci Rep</a>.</p>

</div>
<div *ngIf="panelId == 348">
<p>Characterization of Ksg1 protein kinase-dependent phosphoproteome in the fission yeast S. pombe.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.bbrc.2024.150895'>Biochem Biophys Res Commun</a>.</p>

</div>
<div *ngIf="panelId == 349">
<p>Uridylation regulates mRNA decay directionality in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1038/s41467-024-50824-w'>Nat Commun</a>.</p>

</div>
<div *ngIf="panelId == 350">
<p>Antagonistic effects of mitochondrial matrix and intermembrane space proteases on yeast aging.</p>



<p>Originally published in <a href='https://doi.org/10.1186/s12915-022-01352-w'>BMC Biol</a>.</p>

</div>
<div *ngIf="panelId == 351">
<p>Topoisomerase 1 facilitates nucleosome reassembly at stress genes during recovery.</p>



<p>Originally published in <a href='https://doi.org/10.1093/nar/gkad1066'>Nucleic Acids Res</a>.</p>

</div>
<div *ngIf="panelId == 352">
<p>A connection between the ribosome and two S. pombe tRNA modification mutants subject to rapid tRNA decay.</p>



<p>Originally published in <a href='https://doi.org/10.1371/journal.pgen.1011146'>PLoS Genet</a>.</p>

</div>
<div *ngIf="panelId == 353">
<p>A systematic screen identifies Saf5 as a link between splicing and transcription in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1371/journal.pgen.1011316'>PLoS Genet</a>.</p>

</div>
<div *ngIf="panelId == 354">
<p>Efn1 and Efn2 are extracellular 5’-nucleotidases induced during the fission yeast response to phosphate starvation.</p>



<p>Originally published in <a href='https://doi.org/10.1128/mbio.02992-24'>mBio</a>.</p>

</div>
<div *ngIf="panelId == 355">
<p>A meiotic driver hijacks an epigenetic reader to disrupt mitosis in noncarrier offspring.</p>



<p>Originally published in <a href='https://doi.org/10.1073/pnas.2408347121'>Proc Natl Acad Sci U S A</a>.</p>

</div>
<div *ngIf="panelId == 356">
<p>Structural duality enables a single protein to act as a toxin-antidote pair for meiotic drive.</p>



<p>Originally published in <a href='https://doi.org/10.1073/pnas.2408618121'>Proc Natl Acad Sci U S A</a>.</p>

</div>
<div *ngIf="panelId == 357">
<p>Cbf11 and Mga2 function together to activate transcription of lipid metabolism genes and promote mitotic fidelity in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1371/journal.pgen.1011509'>PLoS Genet</a>.</p>

</div>
<div *ngIf="panelId == 358">
<p>Formation of Transient Protein Aggregate-like Centers Is a General Strategy Postponing Degradation of Misfolded Intermediates.</p>



<p>Originally published in <a href='https://doi.org/10.3390/ijms241311202'>Int J Mol Sci</a>.</p>

</div>
<div *ngIf="panelId == 359">
<p>Rho1 and Rgf1 establish a new actin-dependent signal to determine growth poles in yeast independently of microtubules and the Tea1-Tea4 complex.</p>



<p>Originally published in <a href='https://doi.org/10.1371/journal.pbio.3002491'>PLoS Biol</a>.</p>

</div>
<div *ngIf="panelId == 360">
<p>Novel TORC1 inhibitor Ecl1 is regulated by phosphorylation in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1111/acel.14450'>Aging Cell</a>.</p>

</div>
<div *ngIf="panelId == 361">
<p>Pck2 association with the plasma membrane and efficient response of the cell integrity pathway require regulation of PI4P homeostasis by exomer.</p>



<p>Originally published in <a href='https://doi.org/10.1098/rsob.240101'>Open Biol</a>.</p>

</div>
<div *ngIf="panelId == 362">
<p>The Myosin-V Myo51 and Alpha-Actinin Ain1p Cooperate during Contractile Ring Assembly and Disassembly in Fission Yeast Cytokinesis.</p>



<p>Originally published in <a href='https://doi.org/10.3390/jof10090647'>J Fungi (Basel)</a>.</p>

</div>
<div *ngIf="panelId == 363">
<p>The ortholog of human REEP1-4 is required for autophagosomal enclosure of ER-phagy/nucleophagy cargos in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1371/journal.pbio.3002372'>PLoS Biol</a>.</p>

</div>
<div *ngIf="panelId == 364">
<p>The ortholog of human DNAJC9 promotes histone H3-H4 degradation and is counteracted by Asf1 in fission yeast.</p>



<p>Originally published in <a href='https://doi.org/10.1093/nar/gkaf036'>Nucleic Acids Res</a>.</p>

</div>
<div *ngIf="panelId == 365">
<p>A comprehensive Schizosaccharomyces pombe atlas of physical transcription factor interactions with proteins and chromatin.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.molcel.2025.01.032'>Mol Cell</a>.</p>

</div>
<div *ngIf="panelId == 366">
<p>Limiting 20S proteasome assembly leads to unbalanced nucleo-cytoplasmic distribution of 26S/30S proteasomes and chronic proteotoxicity.</p>



<p>Originally published in <a href='https://doi.org/10.1016/j.isci.2024.111095'>iScience</a>.</p>

</div>
<div *ngIf="panelId == 367">
<p><app-recent-community-pubs></app-recent-community-pubs></p>

</div>
<div *ngIf="panelId == 368">
<p>Browse PomBase’s inventory of conserved proteins with no known biological role</p>

</div>
<div *ngIf="panelId == 369">
<p>The fission yeast <a routerLink="/browse-curation/fission-yeast-bp-go-slim-terms">GO slim</a> provides a summary of genes annotated to broad biological processes</p>

</div>
<div *ngIf="panelId == 370">
<p>Use the <a routerLink="/query">Advanced Search</a> to construct complex queries (GO, phenotypes, taxonomic distribution, domain, chromosomal location).</p>

</div>
<div *ngIf="panelId == 371">
<p>PomBase has integrated over 200,000 manually curated gene-specific annotations. See our <a routerLink="/about/resource-metrics">Resource Metrics</a> page for more literature curation progress reports.</p>

</div>
<div *ngIf="panelId == 372">
<p>PomBase uses manually curated Gene Ontology annotation to generate biological process-specific networks, such as the cytokinesis network excerpted above. Currently available networks are linked to the <a routerLink="/browse-curation/fission-yeast-bp-go-slim-terms">GO slim</a> page.</p>

</div>
<div *ngIf="panelId == 373">
<p>PomBase provides extensive documentation and answers many questions in the <a routerLink="/faq">FAQ</a>. For any information you can’t find there, please <app-contact-email [linkName]="'contact the helpdesk'" [subject]="'PomBase question'"></app-contact-email>.</p>

</div>
<div *ngIf="panelId == 374">
<p>PomBase has pioneered a community curation system that enables researchers to contribute publication-based annotations directly to database curators. To participate, search for the PubMed ID of your paper <a href="https://curation.pombase.org/pombe">in Canto</a> or <app-contact-email [linkName]="'contact the helpdesk'" [subject]="'PomBase question'"></app-contact-email>.</p>

</div>
<div *ngIf="panelId == 375">
<p>4020 of the 5070 fission yeast protein-coding genes now have assigned standard names. We encourage all authors and reviewers to ensure that gene names are registered prior to publication. Visit our <a routerLink="/gene-names">gene registry page</a> and the <a routerLink="/gene-names/gene-naming-guidelines"><em>S. pombe</em> gene naming guidelines</a> for more information.</p>

</div>
<div *ngIf="panelId == 376">
<p>Of the 3526 <em>S. pombe</em> with identified human orthologs, over 500 have been implicated in disease. These are manually annotated with terms from a small in-house vocabulary, and can be searched as described in the <a routerLink="/faq/how-can-i-find-s.-pombe-genes-associated-human-disease">disease gene FAQ</a>.</p>

</div>
<div *ngIf="panelId == 377">
<p>A guide to getting the most from PomBase, and to its curation philosophy and data organization. <a href="https://link.springer.com/protocol/10.1007/978-1-4939-7737-6_4">Free download</a> thanks to the <a href="https://wellcome.ac.uk/">Wellcome Trust</a>.</p>



<p><a href="https://link.springer.com/protocol/10.1007/978-1-4939-7737-6_4">View chapter …</a></p>

</div>
<div *ngIf="panelId == 378">
<p>Unknown process now down to 696, <a routerLink="/gene_subset/characterisation_status_conserved_unknown">conserved unknowns 410</a>.</p>

<p>Our “Unknowns” manuscript is now available on bioRxiv: <a href="https://www.biorxiv.org/content/early/2018/11/16/469569">DOI: 10.1101/469569</a>.</p>

</div>
<div *ngIf="panelId == 379">
<p>Access <a routerLink="/term/MONDO:0000001">Disease associations</a> for 907 pombe genes (up from 610), includes new associations derived from MalaCards. This number will increase as work is still in progress…</p>

</div>
<div *ngIf="panelId == 380">
<p>Our “unknowns” analysis is now published in <a href="https://royalsocietypublishing.org/doi/10.1098/rsob.180241">Open Biology</a>. You can browse the <a routerLink="/status/priority-unstudied-genes">list of fission yeast unknown proteins</a>, and use the “Visualise” button to explore the data interactively in QuiLT, the new tool inspired by <a href="https://royalsocietypublishing.org/doi/10.1098/rsob.180241#RSOB180241F4">Figure 4</a>.</p>

</div>
<div *ngIf="panelId == 381">
<p>PomBase’s advanced search now allows you to retrieve <a routerLink="/documentation/pombase-go-slim-documentation">GO slim</a> annotations for any set of search results. Combine with the “Gene IDs” query option to find GO slim annotations for your own list of genes.</p>

</div>
<div *ngIf="panelId == 382">
<p>The PomBase <a routerLink="/motif_search">Protein Motif Search Tool</a> allows you to search for any user-defined amino acid sequence, supporting exact matches, wildcards, gaps, and ambiguity codes. This search is now fully integrated into PomBase, allowing results to be sent directly to the <a routerLink="/query">advanced search</a>.</p>

</div>
<div *ngIf="panelId == 383">
<p>The PomBase <a href="https://www.pombase.org/jbrowse/">JBrowse instance</a> provides a responsive online environment in which to visualise published genomic datasets. For more information please see our <a href="https://www.pombase.org/documentation/JBrowse_quick_start">JBrowse documentation page</a>. Authors are welcome to submit their published data to PomBase for hosting.</p>

</div>
<div *ngIf="panelId == 384">
<p>Authors now contribute curation to PomBase for almost all new fission yeast publications. Find out how you can benefit in our <a href="https://academic.oup.com/database/article/doi/10.1093/database/baaa028/5827230">new paper on community curation</a>, and visit the <a href="https://curation.pombase.org/pombe"><em>S. pombe</em> Canto home</a> to participate.</p>

</div>
<div *ngIf="panelId == 385">
<p>Access <a routerLink="/term/MONDO:0000001">Disease associations</a> for 1239 of the 3540 pombe genes with human orthologs (up from 987 in 2019), using the MONDO disease ontology. Or browse the <a href="https://www.pombase.org/browse-curation/disease-slim">disease slim set here</a>.</p>

</div>
<div *ngIf="panelId == 386">
<p><a href="https://www.micropublication.org/journals/biology/species/s-pombe/"><em>S. pombe</em> microPublications</a> describe brief, novel findings, negative and/or reproduced results, and results which may lack a broader scientific narrative. Find microPublications in the PomBase simple search, or <a href="https://www.micropublication.org/submit-new-article/">submit your own</a>.</p>

</div>
<div *ngIf="panelId == 387">
<p>Browse <a routerLink="/term/MONDO:0000001">Disease associations</a>, annotated using the Mondo disease ontology, for 1401 (up from 907 in 2019 and 1239 in 2020) of the 3560 <em>S. pombe</em> genes that have human orthologs. See the <a routerLink="/browse-curation/disease-slim">disease slim set</a> for a summary view.</p>

</div>
<div *ngIf="panelId == 388">
<p>Browse <a routerLink="/term/MONDO:0000001">Disease associations</a>, annotated using the Mondo disease ontology, for 1471 (up from 1401 in August 2021) of the 3597 <em>S. pombe</em> genes that have human orthologs. See the <a routerLink="/browse-curation/disease-slim">disease slim set</a> for a summary view.</p>

</div>
<div *ngIf="panelId == 389">
<p>We cloned PomBase to create a fully community-maintained resource for the <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3789812/">emerging model S. japonicus</a>; providing genome browser, literature curation, and query tools.</p>



<p><a href="https://www.japonicusdb.org/">Visit JaponicusDB</a></p>

</div>
<div *ngIf="panelId == 390">
<p><a href="https://pombase.github.io/canto_tutorial/">A new website</a> with videos and documentation for <a href="https://curation.pombase.org/">Canto</a>, our community curation tool. The website includes short step-by-step text and video guides on how to make annotations in Canto.</p>



<p><a href="https://pombase.github.io/canto_tutorial/">Visit the new Canto documentation</a></p>

</div>
<div *ngIf="panelId == 391">
<p>Although over half of fission yeast proteins are now published in small-scale experiments, and the roles of a further &gt;1900 can be inferred, there are still 616 of unknown process, of these 356 are conserved outside the fission yeast, 126 as far as human.</p>



<p><a href="https://www.pombase.org/status/gene-characterisation">View the protein characterization graph</a></p>

</div>
