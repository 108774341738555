<div *ngIf="getIFrameURL()">
  <div class="header-section">
  <div>
    <div class="title">
      <div *ngIf="gocamDetailsList.length == 0">
        <span class="model-title-or-id">GO-CAM pathway model {{gocamIdParam}}</span>
      </div>
      <div *ngIf="gocamDetailsList.length == 1">
        GO-CAM pathway model
        <span class="model-title-or-id">
          <app-description-display [description]="gocamDetailsList[0].title" [descriptionParts]="titleParts[0]">
          </app-description-display>
        </span>
        <span *ngIf="sourcePageType != 'docs'">
          <span class="help-link"><a routerLink="/documentation/go-cam-pathway-models">All curated pathways</a></span>
        </span>
      </div>

      <div *ngIf="gocamDetailsList.length > 1" class="merged-model-list">
        <span *ngIf="overlappingGene">
          Merged GO-CAM pathway models with overlapping gene
          <app-gene-link [gene]="overlappingGene"></app-gene-link>:
        </span>
        <span *ngIf="!overlappingGene">Merged GO-CAM pathway models:</span>
         <div class="model-title-or-id" *ngFor="let detail of gocamDetailsList; let idx = index">
            <a *ngIf="source" routerLink="/pombase_gocam_view/docs/{{detail.gocam_id}}/{{source}}">{{detail.gocam_id}}</a>
            <a *ngIf="!source" routerLink="/pombase_gocam_view/docs/{{detail.gocam_id}}">{{detail.gocam_id}}</a>
            <app-description-display
             [description]="detail.title"
             [descriptionParts]="titleParts[idx]">
            </app-description-display>
         </div>
      </div>
    </div>

    <div class="return-to-gene-page">
    <div *ngIf="sourcePageType == 'gene'">
      <a routerLink="/gene/{{source}}">Return to the {{sourceName || source}} gene page</a>
    </div>
    <div *ngIf="sourcePageType == 'term'">
      <a routerLink="/term/{{source}}">Return to the term page for {{sourceName}} ({{source}})</a>
    </div>
    <div *ngIf="sourcePageType == 'docs'">
      <a routerLink="/documentation/go-cam-pathway-models">Return to the "All curated pathways" list</a>
    </div>
    <div class="highlighted-activities-desc" *ngIf="sourcePageType == 'subset-count'">
      <span>Highlighted activities:</span> {{sourceName}}
    </div>
    </div>
  </div>

  <div *ngIf="contributorNames" class="attribution">
    Curated by: {{contributorNames}}
  </div>
  </div>

  <div class="iframe-container">
    <div *ngIf="modelGenes.length > 0" class="genes-and-terms">
      <div>
        Genes in this model:
        <ul>
          <li *ngFor="let geneSumm of modelGenes">
            <app-gene-link [gene]="geneSumm" [long]="false"></app-gene-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="go-cam-iframe">
      <iframe [src]="getIFrameURL()" class="full-go-cam-view" #gocamiframe>
      </iframe>
    </div>
  </div>
</div>

