<div *ngIf="hasGoCam">
  <div class="full-view-header">
    <div>
      <span>GO-CAM model
        <span class="model-title" *ngIf="gocams.length == 1">{{currentGoCam()!.title || currentGoCamId}}</span>
        <span classclass="model-select" *ngIf="gocams.length > 1 ">
          <select [(ngModel)]="currentGoCamId" (ngModelChange)="modelChange()" class="go-cam-select">
            <option *ngFor="let gocam of gocams" [ngValue]="gocam.gocam_id">
              {{gocam.title || gocam.gocam_id}}
            </option>
          </select>
        </span>
      </span>
      <span class="help-link">
        <a routerLink="/documentation/go-cam-pathway-models">All curated pathways</a>
      </span>
    </div>
    <div>
      <span class="large-view"><a routerLink="{{largeViewPath}}">Show larger view ...</a></span>
      <span class="pombase-view" *ngIf="!deployConfigService.productionMode()">
        <a routerLink="{{pomBaseViewPath}}">Show PomBase view ...</a>
      </span>
    </div>
  </div>

  <div *ngFor="let o of [geneOrTermDetails]">
  <iframe [src]="getIFrameURL()" class="embedded-go-cam-viewer" #gocamiframe>
  </iframe>
  </div>
</div>

<div *ngIf="!hasGoCam && displayName" class="no-go-cams">
  No GO-CAM models available for {{displayName}}
</div>
